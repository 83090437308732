import React, {useEffect, useState} from 'react';
import './profile.css';
import {BackButton, useInitData} from '@vkruglikov/react-telegram-web-app';
import HeaderMenu from '../header-menu/header-menu';
import {EnergyProps, TotalElementsProps} from '../../screens/overview';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
interface ProfileProps {
  totalLives: number;
  totalMoves: number;
  totalEnergies: EnergyProps | null;
  totalElements: TotalElementsProps | null;
  moves: string[];
  goBack: () => void;
  pauseAudio: () => void;
}

const shuffleArray = (array: []) => {
  let currentIndex = array.length,
    randomIndex;
  // Пока не перемешаем все элементы
  while (currentIndex !== 0) {
    // Выбираем оставшийся элемент
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    // Меняем местами текущий элемент с выбранным
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
  return array;
};

const ProfileScreen: React.FC<ProfileProps> = ({
  totalLives,
  totalMoves,
  totalEnergies,
  totalElements,
  moves,
  goBack,
  pauseAudio,
}) => {
  const [initDataUnsafe, initData] = useInitData() || '';
  const navigate = useNavigate();
  const back = () => {
    navigate(-1);
  };
  const [query, setQuery] = useState('');
  const fetchUserData = async () => {
    try {
      const response = await axios.post(`/api/getUserInfo`, {
        _auth: initData,
        data: ['query'],
      });
      setQuery(response.data.data.query);
    } catch (error) {
      console.error('Ошибка при отправке данных', error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);
  const [state, setState] = useState({days: 0, dice: 0});
  const [data, setData] = useState([]);
  useEffect(() => {
    // Извлекаем значения dice и перемешиваем их
    const diceValues = (moves as any).map((item: any) => item.dice);
    const shuffledDiceValues = shuffleArray(diceValues);

    // Обновляем данные с перемешанными значениями
    const updatedData = (moves as any).map((item: any, index: any) => ({
      ...item,
      dice: shuffledDiceValues[index],
    }));

    setData(updatedData);
  }, [moves]);
  useEffect(() => {
    const totalDice = moves.reduce(
      (accumulator, move: any) => accumulator + move.dice,
      0,
    );
    const totalDays = moves.reduce(
      (accumulator, move: any) => accumulator + move.days_ago,
      0,
    );
    setState((prev) => ({...prev, dice: totalDice, days: totalDays}));
  }, [moves]);
  // console.log(moves);
  return (
    <div className='container'>
      <BackButton onClick={goBack} />
      <HeaderMenu pauseAudio={pauseAudio} />
      <div className='title__container mt-top'>
        <div className='title b-text'>{'Об игроке:'}</div>
        <br />
        <p>
          <b>Твой запрос:</b> {query}
        </p>
      </div>

      <div className='energy-items'>
        <div className='energy-items__row'>
          <div className={`energy-items_item energy-items__life`}>
            <span></span>
            <p>
              <span className='name-item'> Прожито жизней</span> {totalLives}{' '}
            </p>
          </div>
        </div>
      </div>
      <div className='energy-items'>
        <div className='energy-items__row'>
          <div className={`energy-items_item energy-items__dice`}>
            <span></span>{' '}
            <p>
              <span className='name-item'> Сделано ходов</span> {totalMoves}
            </p>
          </div>
        </div>
      </div>
      <div className='energy-items'>
        <div className='energy-items__row'>
          <div className={`energy-items_item energy-items__day`}>
            <span></span>{' '}
            <p>
              <span className='name-item'> Дней в игре</span> {state.days}
            </p>
          </div>
        </div>
      </div>
      <div className='energy-items'>
        <div className='energy-items__row'>
          <div className={`energy-items_item energy-items__tokens`}>
            <span></span>
            <p>
              <span className='name-item'>Всего получено токенов</span>{' '}
              {state.dice}
            </p>
          </div>
        </div>
      </div>
      <div className='energy-items'>
        <p className='energy-items__title'>Собрано элементов:</p>
        <div className='energy-items__row'>
          {totalElements !== null &&
            (Object.keys(totalElements) as Array<keyof TotalElementsProps>).map(
              (prop) => (
                <div
                  className={`energy-items_item energy-items__${prop}`}
                  key={prop}
                >
                  <span></span>

                  <p className='energy-item__component'>
                    <span className='item-title'>
                      {' '}
                      {prop === 'air'
                        ? 'Воздух'
                        : prop === 'water'
                          ? 'Вода'
                          : prop === 'fire'
                            ? 'Огонь'
                            : 'Земля'}{' '}
                    </span>
                    <span className='number'>{totalElements[prop]}</span>
                  </p>
                </div>
              ),
            )}
        </div>
        <br />
      </div>
      <div className='energy-items'>
        <p className='energy-items__title'>Собрано энергий:</p>
        <div className='energy-items__row'>
          {totalEnergies !== null &&
            (Object.keys(totalEnergies) as Array<keyof EnergyProps>).map(
              (prop) => (
                <div
                  className={`energy-items_item energy-items__${prop}`}
                  key={prop}
                >
                  <span></span>
                  <p>
                    {prop === 'female' ? 'Женской ' : 'Мужской '}
                    {totalEnergies[prop]}
                  </p>
                </div>
              ),
            )}
        </div>
        <br />
      </div>
      <div className='title__container mt-top'>
        <div className='title b-text'>{'История ходов:'}</div>
      </div>
      <div className='profile-desc desc__container'>
        <div className='desc left'>
          {moves.length !== 0 && (
            <div className='energy-item__row'>
              <p className='energy-item__number'>Номер</p>
              <p className='energy-item__cell'>Клетка</p>
              <p>Кубик</p>
              <p className='energy-item__days'>Дней</p>
            </div>
          )}
          {/* <div className='energy-item__row'>
            <p className='energy-item__number'>Номер</p>
            <p className='energy-item__cell'>Клетка</p>
            <p>Кубик</p>
            <p className='energy-item__days'>Дней</p>
          </div>
          <div className={`energy-items_item energy-items__step`}>
            <span>{0}</span>{' '}
            <p>
              <span className='symbol'> </span>
              <span className='energy-items__title'> Заблуждение</span>
              <span className='energy-items_dice'> +{5}</span>
              <span className='days-ago'>
                {totalLives !== 0 && `-${0}`}
              </span>{' '}
            </p>
          </div>{' '}
          <div className={`energy-items_item energy-items__step`}>
            <span>{0}</span>{' '}
            <p>
              <span className='symbol'> </span>
              <span className='energy-items__title'>Гнев
              <span className='energy-items_dice'> +{5}</span>
              <span className='days-ago'>
                {totalLives !== 0 && `-${0}`}
              </span>{' '}
            </p> */}
          {/* </div> */}
          {(moves as any).map((move: any, index: any) => (
            <div className={`energy-items_item energy-items__step`}>
              <span>{move.field}</span>{' '}
              <p key={index}>
                {/* <span className='symbol'> {move.symbol}</span> */}
                <span className='energy-items__title'>{move.name}</span>
                <span className='energy-items_dice'>
                  {move.dice === 666 && '👁'}
                  {move.dice === 0 && '🔒'}
                  {move.dice !== 666 && move.dice !== 0 && move.dice
                    ? `+${move.dice}`
                    : move.symbol}
                </span>
                <span className='days-ago'>
                  {totalLives !== 0 && `-${move.days_ago}`}
                </span>{' '}
              </p>
            </div>
          ))}
        </div>
        {/* <div className='desc left'> */}
      </div>
      <br />
      <div className='buttons__container'>
        <button onClick={back} className='button profile-button'>
          Hазад
        </button>
      </div>
      {/* <div className='energy-items'>
        <div className='energy-items__row'></div>
        <br />
      </div> */}
      {/* </div> */}
    </div>
  );
};

export default ProfileScreen;
