import img_Start from '../assets/images/Start-2.jpg';
import img_ComeCloser from '../assets/images/come-closer-2.jpg';
// import img_ComeCloser from '../assets/images/ComeCloser-2.jpg';
import secondLive from '../assets/images/secondLive.jpg';
import img_Hide from '../assets/images/Hide-2.jpg';
import img from '../assets/images/insight-3.png';

import arrow from '../assets/images/arrow.png';
import img_CubeLess_1 from '../assets/images/CubeLess-1.jpg';
import img_CubeLess_2 from '../assets/images/CubeLess-2.jpg';
import img_PresentBuddha from '../assets/images/PresentBuddha-2.jpg';
import img_AnswerQuestion from '../assets/images/AnswerQuestion-2.jpg';
import img_BuddhaNice from '../assets/images/BuddhaNice-2.jpg';
import img_BuddhaAnswerQuestionWhy from '../assets/images/BuddhaAnswerQuestionWhy-2.jpg';
import img_Request from '../assets/images/Request-2.jpg';
import img_Enter from '../assets/images/Enter-2.jpg';
import img_DoNotEnter from '../assets/images/DoNotEnter-2.jpg';
import img_ClarifyRequest from '../assets/images/ClarifyRequest-2.jpg';
import img_RightRequest from '../assets/images/RightRequest.jpg';
import img_Cube from '../assets/images/Cube-2.jpg';
import img_ifCubesEnough from '../assets/images/CubesEnough-2.jpg';
import img_ifCubesLess3 from '../assets/images/if_CubesLess3-2.jpg';
import img_like from '../assets/images/Insight-2.png';
import img_StartGuide from '../assets/images/StartGuide-2.jpg';
import img_ChooseGuide from '../assets/images/ChooseGuide-2.jpg';
import img_AboutGuides from '../assets/images/AboutGuides-2.jpg';
import img_ContactBuddha from '../assets/images/ContactBuddha-2.jpg';
import birth from '../assets/images/new-byddha.png';
import birth_1 from '../assets/images/new-byddha_1.png';

import {TooltipProps} from '../components/tooltip/tooltip';
import {TagProps} from '../API/API';

interface WelcomeScreenData {
  subtitle?: string;
  tooltip?: TooltipProps[];
  isUpdate?: boolean; // need to  delete
  path: string;
  text: string;
  buttons: Record<string, string>;
  img: string;
  input_for: string | boolean;
  with_dice: boolean;
  statsDesc?: string;
  tag?: TagProps;
  textIsCenter?: 'true' | 'none';
}

export const welcomeScreenData: WelcomeScreenData[] = [
  {
    // textIsCenter: 'none',
    path: 'Start',
    isUpdate: true,
    subtitle: `Ты в темной комнате... Не помнишь, что было до. Она наполнена разными вещами, но в то же время в ней как будто ничего нет. Вопросов больше, чем ответов...`,
    text: 'Впереди ты видишь проход в стене, из которого исходит яркий неестественный свет. Ты хочешь узнать, что там?',
    buttons: {
      'welcome/Hide': 'Остаться',
      'welcome/ComeCloser': 'Подойти',
    },
    img: img_Start,
    input_for: false,
    with_dice: false,
    statsDesc: 'The user logged into the app',
    tag: 'Welcome',
  },
  {
    isUpdate: true,
    path: 'ComeCloser',
    subtitle: `Ты подходишь ближе. Тебя озаряет яркий свет и ослепляет глаза`,
    // text: 'Впереди ты видишь проход в стене, из которого исходит яркий неестественный свет. Ты хочешь узнать, что там?',
    text: 'Вдруг ты слышишь тихий, но уверенный голос. "Становится все интереснее", - думаешь ты и делаешь шаг вперед...',
    buttons: {
      'welcome/PresentBuddha': 'Кто там?',
    },
    tooltip: [
      {
        text: [`Подойди! Смелее!`],
        position: 'center',
        textPosition: 'top-center',
        isTransparent: true,
      },
    ],
    img: img_ComeCloser,
    input_for: false,
    with_dice: false,
    statsDesc: 'Pressed the "approach" button',
    tag: 'Welcome',
  },
  {
    isUpdate: true,
    path: 'Hide',
    text: 'Такое чувство, что кто-то приближается к тебе. Свет становится все ярче и начинает слепить глаза. Вдруг ты неожиданно слышишь голос. Он будто наполняет тебя уверенностью и ты делаешь шаг вперед...',
    buttons: {
      'welcome/PresentBuddha': 'Кто там?',
    },
    tooltip: [
      {
        text: [`Подойди! Смелее!`],
        position: 'top-center',
        textPosition: 'top-center',
      },
    ],
    // img: img_Hide,
    img: img_Start,
    input_for: false,
    with_dice: false,
    statsDesc: 'Pressed the "stay" button',
    tag: 'Welcome',
  },
  {
    isUpdate: true,
    path: 'PresentBuddha',
    textIsCenter: 'true',
    tooltip: [
      {
        text: [`Живи долго и процветай, Герой!`],
        position: 'center',
        textPosition: 'top-left',
      },
    ],
    text: '',
    buttons: {
      'welcome/AnswerQuestion': 'Кто ты?',
    },
    img: img_PresentBuddha,
    input_for: false,
    with_dice: false,
    statsDesc: 'Navigated to the page with Buddha`s greeting',
    tag: 'Welcome',
  },
  {
    isUpdate: true,
    path: 'AnswerQuestion',
    text: '',
    tooltip: [
      {
        text: [
          `Мое имя YoungBuddha. Я Хранитель древней таинственной игры Лила. Твоя игра уже началась. Все, что с тобой будет происходить не случайно`,
        ],
        position: 'center-top',
        textPosition: 'top-left',
      },
      {
        text: [`Хочешь начать свой путь в Лиле, Герой?`],
        position: 'center-bottom',
        textPosition: 'top-left',
      },
    ],
    buttons: {
      'welcome/BuddhaAnswerQuestionWhy': 'Зачем?',
      'welcome/BuddhaNice': 'Хочу',
    },
    img: img_AnswerQuestion,
    input_for: false,
    with_dice: false,
    statsDesc: 'Navigated to the page with the description of Buddha',
    tag: 'Welcome',
  },
  {
    isUpdate: true,
    path: 'BuddhaNice',
    tooltip: [
      {
        text: [`Веришь мне?`],
        position: 'center-bottom',
        textPosition: 'top-left',
      },
      {
        text: [
          `Я был уверен в тебе, Герой! Готовность начать свой путь в Лиле - верный знак. Открой сердце, включи внимание и ты увидишь как игра связана с реальной жизнью`,
        ],
        position: 'top',
        textPosition: 'bottom-right',
        // isNarrow: 'narrow-left',
      },
    ],

    text: '',
    buttons: {
      'welcome/Request': 'Верю тебе',
    },
    img: img_BuddhaNice,
    input_for: false,
    with_dice: false,
    statsDesc: 'Pressed the "ready" button',
    tag: 'Welcome',
  },
  {
    isUpdate: true,
    path: 'BuddhaAnswerQuestionWhy',
    textIsCenter: 'true',
    tooltip: [
      {
        text: [`Это правильный вопрос, Герой!`],
        position: 'top',
        textPosition: 'bottom-left',
      },
      {
        text: [
          `Лила поможет лучше узнать и понять себя, развить внимание и дисциплину, достигнуть целей. Вместе с Лилой ты создашь лучшую версию себя и будешь чувствовать себя счастливее!`,
        ],
        position: 'center',
        textPosition: 'top-left',
      },
      // {
      //   text: [
      //     `Лила поможет лучше узнать и понять себя, развить внимание и дисциплину, достигнуть целей. Вместе с Лилой ты создашь лучшую версию себя и будешь чувствовать себя счастливее!Ты готов?`,
      //   ],
      //   position: 'bottom',
      //   textPosition: 'top-left',
      // },
    ],

    text: '',
    buttons: {
      'welcome/Request': 'Давай начнем',
    },
    img: img_BuddhaAnswerQuestionWhy,
    input_for: false,
    with_dice: false,
    statsDesc: 'Pressed the "why" button',
    tag: 'Welcome',
  },
  {
    isUpdate: true,
    path: 'Request',
    textIsCenter: 'true',
    text: '',
    tooltip: [
      {
        text: [
          `Первое, с чего нужно начать - понять, на что и как ты будешь играть. В Лиле мечту мы называем запросом`,
        ],
        position: 'top',
        textPosition: 'bottom-right',
        // isNarrow: 'narrow-right',
      },
      {
        text: [
          `Закрой глаза. Подумай, о чем ты сейчас мечтаешь? Чего хочешь достичь?`,
        ],
        position: 'center-bottom',
        textPosition: 'top-left',
        // isNarrow: 'narrow-left',
      },
      {
        text: [
          `- Итак, у тебя есть запрос? 
          \n- Или ты хочешь, чтобы Лила помогла тебе его найти? `,
        ],
        position: 'center-top',

        textPosition: 'top-left',
        // isNarrow: 'narrow-right',
      },
      // {
      //   text: [`– Или я позволю игре поддержать меня в поиске мечты?`],
      //   position: 'bottom',
      //   textPosition: 'top-left',
      //   isNarrow: 'narrow-left',
      // },
    ],
    buttons: {
      'welcome/DoNotEnter': 'Нет запроса',
      'welcome/Enter': 'Есть запрос',
    },
    img: img_Request,
    input_for: false,
    with_dice: false,
    statsDesc:
      'Navigated to the page with the options: "Have a request" and "Play on request',
    tag: 'Welcome',
  },
  {
    isUpdate: true,
    path: 'Enter',
    text: '',
    textIsCenter: 'true',
    tooltip: [
      {
        text: [
          `Первый шаг очень значим. Поэтому очень важно правильно сформулировать запрос в одно предложение`,
        ],
        position: 'top',
        textPosition: 'bottom-right',
      },
      {
        text: [
          `Он должен быть: 
- Ценным именно для тебя 
- Четким и понятным
- Положительно окрашенным
- На одну тему
`,
        ],
        position: 'center-middle',
        textPosition: 'top-left',
        // isNarrow: 'narrow-left',
      },
      {
        text: ['Напиши запрос в поле'],
        position: 'bottom',
        textPosition: 'top-left',
        // isNarrow: 'narrow-left',
      },
    ],
    buttons: {
      'welcome/ClarifyRequest': 'Дальше',
    },
    img: img_Enter,
    input_for: 'query',
    with_dice: false,
    statsDesc: 'The user has a request',
    tag: 'Welcome',
  },
  {
    isUpdate: true,
    path: 'DoNotEnter',
    text: '',
    buttons: {
      'welcome/toCell': 'Начать игру',
    },
    textIsCenter: 'true',
    tooltip: [
      {
        text: [
          `Многие игроки начинают свой путь в игре без запроса. Это нормальная практика`,
        ],
        position: 'top',
        textPosition: 'bottom-right',
      },
      {
        text: [
          `Игра Лила - мощный инструмент, который помог многим игрокам понять истинные желания`,
        ],
        position: 'center-middle',
        textPosition: 'top-left',
      },
      {
        text: [
          `Доверься ей, и она поможет тебе понять, чего ты на самом деле хочешь`,
        ],
        position: 'bottom',
        textPosition: 'top-left',
      },
    ],
    img: img_DoNotEnter,
    input_for: false,
    with_dice: false,
    statsDesc: 'The user hasn`t a request',
    tag: 'Welcome',
  },
  {
    textIsCenter: 'none',
    isUpdate: true,
    path: 'ClarifyRequest',
    text: '',

    tooltip: [
      {
        text: [`Произнеси вслух свой запрос, Герой!`],
        position: 'top-top',
        textCenter: true,
        // isNarrow: 'narrow-right',
        textPosition: 'botom-left',
      },
      {
        text: [
          `Задай себе вопросы:
- Он обо мне, а не о ком-то или для кого-то?
- Я чётко представляю его?
- Я чувствую радость при мысли о нем? 
`,
        ],
        textCenter: true,
        position: 'center-middle',
        textPosition: 'top-right',
      },
    ],
    buttons: {
      'welcome/Enter': 'Изменить запрос',
      'welcome/RigthRequest': 'Это мой запрос',
    },
    img: img_ClarifyRequest,
    input_for: false,
    with_dice: false,
    statsDesc: 'Navigated to the request confirmation page',
    tag: 'Request',
  },
  {
    isUpdate: true,
    path: 'RigthRequest',
    textIsCenter: 'true',
    text: '',
    tooltip: [
      {
        text: [
          `Теперь Лила должна проверить твой запрос. 
Для этого нужно выбросить на кубике цифру 6.
На это у тебя будет 3 попытки. 
Как только выпадет 6, ты родишься в игре`,
        ],
        position: 'center',
        textPosition: 'top-left',
      },
      // {
      //   text: [`Нужно бросить 6. Жми “Бросить кубик”`],
      //   position: 'center',
      //   textPosition: 'top-right',
      // },
    ],
    // text: '\n\n– Произнеси самому себе запрос.\n– Это мой запрос или мне его кто-то навязал? Насколько сильно он откликается…?',
    buttons: {
      'welcome/Cube': 'Бросить кубик',
    },
    img: img_RightRequest,
    input_for: false,
    with_dice: false,
    statsDesc: 'The user confirmed the request',
    tag: 'Request',
  },
  {
    isUpdate: true,
    path: 'Cube',

    text: 'Нажми чтобы бросить',
    buttons: {},
    img: img_Cube,
    input_for: false,
    with_dice: true,
    statsDesc: 'The user navigated to the screen with the dice',
    tag: 'Request',
  },
  {
    isUpdate: true,
    path: 'ifCubesEnough',
    tooltip: [
      {
        text: [
          `Поздравляю, Герой! Далеко не каждому удается выкинуть 6 с первого раза! Лила приняла твой запрос`,
        ],
        position: 'top',
        textPosition: 'bottom-left',
      },
      // {
      //   text: [`Жми “Начать игру”!`],
      //   position: 'bottom',
      //   textPosition: 'top-right',
      // },
    ],
    text: '',
    buttons: {
      'welcome/toCell': 'Начать игру',
    },
    img: img_ifCubesEnough,
    input_for: false,
    with_dice: false,
    statsDesc: 'The user rolled a 6.',
    tag: 'Request',
  },
  {
    isUpdate: true,
    path: 'ifCubesLess1',
    text: '',

    tooltip: [
      {
        text: [
          `Тебе не удалось выбросить 6 с первого раза.`,
          'Ничего страшного, у тебя еще две попытки',
        ],
        position: 'top',
        textPosition: 'bottom-right',
        // isNarrow: 'narrow-right',
      },
      {
        text: [`Кидай кубик вновь`],
        position: 'center',
        textPosition: 'top-left',
      },
    ],
    buttons: {
      'welcome/Cube': 'Бросить кубик',
    },
    img: img_CubeLess_1,
    input_for: false,
    with_dice: false,
    statsDesc: 'The user didn`t roll a 6 on the first try',
    tag: 'Request',
  },
  {
    isUpdate: true,
    path: 'ifCubesLess2',
    text: '',
    tooltip: [
      {
        text: [
          `Снова нет, но в игре всегда есть место для новой попытки. Бросай кубик еще раз!`,
        ],
        position: 'bottom',
        textPosition: 'top-right',
      },
    ],
    buttons: {
      'welcome/Cube': 'Бросить кубик',
    },
    img: img_CubeLess_2,
    input_for: false,
    with_dice: false,
    statsDesc: 'The user didn`t roll a 6 on the second try',
    tag: 'Request',
  },
  {
    isUpdate: true,
    path: 'ifCubesLess3-0',
    tooltip: [
      {
        text: [
          'И снова мимо. 3 попытки исчерпаны. Это значит, что Лила не приняла твой запрос',
        ],
        position: 'top',
        textPosition: 'bottom-left',
      },
      {
        text: [
          'Стало быть, есть что-то важнее и ценнее для тебя. Позволь Лиле помочь тебе понять, чего ты хочешь на самом деле',
        ],
        position: 'center',
        textPosition: 'top-right',
      },
    ],
    text: ``,
    buttons: {
      'welcome/DoNotEnter': 'Играть на запрос',
    },
    img: img_ifCubesLess3,
    input_for: false,
    with_dice: false,
    statsDesc: 'The user didn`t roll a 6 on the third try',
    tag: 'Request',
  },
  {
    isUpdate: true,
    path: 'secondLive',
    tooltip: [
      {
        text: [
          `Теперь ты готов к тому, чтобы прожить свою первую жизнь, Герой! 
И проживешь ты ее по-новому. 
Один раз в день кидай кубик и перемещайся по полю`,
        ],
        position: 'top',
        textPosition: 'bottom-left',
      },
      {
        text: [
          `Читай описание и осмысляй клетку применительно к своему запросу. 
Получай ответы от Лилы и записывай свои мысли и открытия`,
        ],
        position: 'center',
        textPosition: 'top-right',
      },
      {
        text: [
          `Помни, у тебя есть Проводник. Задавай ему любые вопросы и обращайся за помощью!`,
        ],
        position: 'bottom',
        textPosition: 'top-right',
      },
    ],
    text: ``,
    buttons: {
      'welcome/Enter': 'Начать',
    },
    img: secondLive,
    input_for: false,
    with_dice: false,
    statsDesc: 'The user didn`t roll a 6 on the third try',
    tag: 'Request',
  },
  {
    isUpdate: true,
    path: 'ifCubesLess3-1',
    text: '',
    tooltip: [
      {
        text: [
          'У тебя не получилось. Игра не принимает твой запрос, поэтому в первой игровой жизни Лила поможет тебе сформулировать запрос и понять, чего ты хочешь на самом деле',
        ],
        position: 'top',
        textPosition: 'bottom-left',
      },
    ],
    buttons: {
      'welcome/Enter': 'Попробовать по-другому',
    },
    img: img_ifCubesLess3,
    input_for: false,
    with_dice: false,
    statsDesc: 'Navigated to the page: "The game did not accept your request."',
    tag: 'Request',
  },
  {
    isUpdate: true,
    path: 'toCell',
    text: '',
    tooltip: [
      // {
      //   text: ['Узнать, чего ты хочешь на самом деле - тоже классный запрос!'],
      //   position: 'top',
      //   textPosition: 'bottom-left',
      //   isNarrow: 'narrow-left',
      // },
      {
        text: [
          `Кидай кубик и перемещайся по игровому полю. 
Получай очки за каждый бросок кубика.
Включи свое внимание и изучай игровое поле`,
        ],
        position: 'top',
        textPosition: 'bottom-left',
      },
      {
        text: [
          `Читай и анализируй описание каждой клетки, ведь твой путь не случаен.
Прислушайся к внутреннему голосу и как только ты найдешь запрос - ты проживешь первую жизнь и закончишь игру`,
        ],
        position: 'bottom',
        textPosition: 'top-left',
      },
      // {
      //   text: ['Встретимся в следующей жизни'],
      //   position: 'bottom',
      //   textPosition: 'top-right',
      //   // isNarrow: 'narrow-left',
      // },
    ],

    buttons: {
      cell: 'Родиться',
    },
    img: img_PresentBuddha,
    input_for: false,
    with_dice: false,
    statsDesc: 'Navigated to the page with the game description',
    tag: 'Request',
  },
  {
    isUpdate: true,
    path: 'insightDone',
    tooltip: [
      {
        text: [
          `Инсайты доступны в Потоке Лилы, Там
можно читать и комментировать инсайты
всех игроков!`,
        ],
        position: 'bottom',
        textPosition: 'top-right',
      },
    ],
    text: '',
    buttons: {
      move: 'Сделать ход',
    },
    img: img,
    input_for: false,
    with_dice: false,
    statsDesc: 'Navigated to the page with insights.',
    tag: 'Conductors',
  },
  {
    isUpdate: true,
    path: 'insightDoneAll',
    tooltip: [
      {
        text: [
          `Инсайты доступны в Потоке Лилы, Там
можно читать и комментировать инсайты
всех игроков!`,
        ],
        position: 'bottom',
        textPosition: 'top-right',
      },
    ],
    text: '',
    buttons: {
      move: 'Сделать ход',
    },
    img: img,
    input_for: false,
    with_dice: false,
    statsDesc: 'Navigated to the page with insights.',
    tag: 'Conductors',
  },
  {
    isUpdate: true,
    path: 'StartGuide',
    text: '',
    tooltip: [
      {
        text: [
          'Ты пока не можешь ходить, но у меня для тебя есть первое задание...',
        ],
        position: 'bottom',
        textPosition: 'top-right',
      },
    ],
    buttons: {
      'welcome/ChooseGuide': 'Что за задание?',
    },
    img: img_StartGuide,
    input_for: false,
    with_dice: false,
    tag: 'Conductors',
    statsDesc: 'The user has lived 1 life',
  },
  {
    isUpdate: true,
    path: 'ChooseGuide',
    tooltip: [
      {
        text: [
          'Поздравляю, Герой! Ты завершил свою нулевую игру. Нулевая она, потому что отличается от того, что будет дальше ',
        ],
        position: 'top',
        textPosition: 'bottom-left',
      },
      {
        text: [
          'Я рад, что ты познакомился с игровым полем и концепцией игры. Ах да, еще ты заработал токены! ',
        ],
        position: 'center',
        textPosition: 'top-right',
      },
      {
        text: [
          'Так как ты играл на запрос, ознакомься с итогами игры, так ты лучше поймешь себя и что делать дальше',
        ],
        position: 'bottom',
        textPosition: 'top-right',
      },
    ],
    text: '',
    buttons: {
      overview: 'Итоги игры',
      // 'welcome/AboutGuides': 'О провод,иках',
    },
    // buttons: {
    //   'welcome/ContactBuddha': 'Выбрать проводника',
    //   'welcome/AboutGuides': 'О проводниках',
    // },
    img: img_ChooseGuide,
    input_for: false,
    with_dice: false,
    statsDesc: 'Navigated to the page with the guide selection',
    tag: 'Conductors',
  },
  {
    isUpdate: true,
    path: 'ChooseGuide2',
    tooltip: [
      {
        text: [
          'Поздравляю тебя, Герой! Ты прожил свою первую жизнь по-настоящему и получил множество ответов ',
        ],
        position: 'top',
        textPosition: 'bottom-left',
      },
      {
        text: [
          'Ты заслужил свою мечту и готов встретиться с ней! Это самый ценный и важный момент',
        ],
        position: 'center',
        textPosition: 'top-right',
      },
      {
        text: [
          'Ознакомься с итогами игры, так ты лучше поймешь себя и что делать дальше',
        ],
        position: 'bottom',
        textPosition: 'top-right',
      },
    ],
    text: '',
    buttons: {
      overview: 'Итоги игры',
      // 'welcome/AboutGuides': 'О провод,иках',
    },
    // buttons: {
    //   'welcome/ContactBuddha': 'Выбрать проводника',
    //   'welcome/AboutGuides': 'О проводниках',
    // },
    img: img_ChooseGuide,
    input_for: false,
    with_dice: false,
    statsDesc: 'Navigated to the page with the guide selection',
    tag: 'Conductors',
  },
  {
    isUpdate: true,
    path: 'endSecondLife',
    tooltip: [
      {
        text: [
          'Поздравляю тебя, Герой! Ты прожил свою первую жизнь по-настоящему и получил множество ответов',
        ],
        position: 'top',
        textPosition: 'bottom-left',
      },
      {
        text: [
          'Ты заслужил свою мечту и готов встретиться с ней! Это самый ценный и важный момент',
        ],
        position: 'center',
        textPosition: 'top-left',
      },
      {
        text: [
          'Следующая игра будет доступна, когда твой Проводник решить что ты готов',
        ],
        position: 'bottom',
        textPosition: 'top-left',
      },
    ],
    text: '',
    buttons: {
      overview: 'К проводнику',
      // 'welcome/AboutGuides': 'О провод,иках',
    },
    // buttons: {
    //   'welcome/ContactBuddha': 'Выбрать проводника',
    //   'welcome/AboutGuides': 'О проводниках',
    // },
    img: img_ChooseGuide,
    input_for: false,
    with_dice: false,
    statsDesc: 'Navigated to the page with the guide selection',
    tag: 'Conductors',
  },
  {
    isUpdate: true,
    path: 'AboutGuides',
    textIsCenter: 'true',
    tooltip: [
      {
        text: [
          'Я уже говорил, что в игре все неслучайно, надо лишь включить свое внимание и открыть сердце',
        ],
        position: 'top',
        textPosition: 'bottom-right',
      },
      {
        text: [
          'Итоги игры очень важно правильно интерпретировать, для того чтобы получить максимум пользы',
        ],
        position: 'center',
        textPosition: 'top-right',
      },
      {
        text: [
          'Поэтому я дарю тебе возможность продолжить свой путь в Лиле с Проводником',
        ],
        position: 'bottom',
        textPosition: 'top-right',
      },
    ],
    text: '',
    buttons: {
      'welcome/ContactBuddha': 'Кто это?',
    },
    img: img_AboutGuides,
    input_for: false,
    with_dice: false,
    statsDesc: 'Navigated to the page with the guide`s description',
    tag: 'Conductors',
  },
  {
    isUpdate: true,
    path: 'AboutGuides2',
    textIsCenter: 'true',
    tooltip: [
      {
        text: [
          'Я уже говорил, что в игре все неслучайно, надо лишь включить свое внимание и открыть сердце',
        ],
        position: 'top',
        textPosition: 'bottom-right',
      },
      {
        text: [
          'Итоги игры очень важно правильно интерпретировать, для того чтобы получить максимум пользы',
        ],
        position: 'center',
        textPosition: 'top-right',
      },
      {
        text: [
          'Поэтому я дарю тебе возможность продолжить свой путь в Лиле с Проводником',
        ],
        position: 'bottom',
        textPosition: 'top-right',
      },
    ],
    text: '',
    buttons: {
      'external:': 'К Проводнику',
    },
    img: img_AboutGuides,
    input_for: false,
    with_dice: false,
    statsDesc: 'Navigated to the page with the guide`s description',
    tag: 'Conductors',
  },

  {
    isUpdate: true,
    path: 'ContactBuddha',
    textIsCenter: 'true',
    tooltip: [
      {
        text: [
          'Твоим первым Проводником будет опытный игрок в Лилу, который прожил уже несколько полных жизней и успешно закрыл запросы',
        ],
        position: 'top',
        textPosition: 'bottom-right',
      },
      {
        text: [
          'Он поможет тебе сформулировать запрос, научит правильно понимать смысл клеток и анализировать траекторию игры. С Проводником ты будешь эффективнее двигаться к своей цели',
        ],
        position: 'bottom',
        textPosition: 'top-right',
      },
    ],
    text: '',
    buttons: {
      'external:https://t.me/LeelaGallery ': 'К Проводнику',
    },
    img: img_ContactBuddha,
    input_for: false,
    with_dice: false,
    statsDesc: 'Navigated to the page with the "Guide Gallery"',
    tag: 'Conductors',
  },
  {
    isUpdate: true,
    path: 'Error',
    textIsCenter: 'true',
    tooltip: [
      {
        text: [
          'Твоим первым Проводником будет опытный игрок в Лилу, который прожил уже несколько полных жизней и успешно закрыл запросы',
        ],
        position: 'top',
        textPosition: 'bottom-right',
      },
      {
        text: [
          'Он поможет тебе сформулировать запрос, научит правильно понимать смысл клеток и анализировать траекторию игры.С Проводником ты будешь эффективнее двигаться к своей цели',
        ],
        position: 'bottom',
        textPosition: 'top-right',
      },
    ],
    text: '',
    buttons: {
      'external:https://t.me/LeelaGallery ': 'К Проводнику',
    },
    img: img_ContactBuddha,
    input_for: false,
    with_dice: false,
    statsDesc: 'Navigated to the page with the "Guide Gallery"',
    tag: 'Conductors',
  },
  {
    isUpdate: true,
    path: 'bithField',
    textIsCenter: 'true',
    tooltip: [
      {
        text: [
          'Поздравляю, ты родился для мечты и мечта родилась для тебя, Герой!',
        ],
        position: 'top',
        textPosition: 'bottom-right',
      },
      {
        text: [
          `Ежедневным важным шагом в игре будет этап рефлексии. 
В игре мы называем это - "написать ответ".  
Это нужно для лучшего понимания себя и приближения к мечте.
Материализуй свои мысли в ответе`,
        ],
        position: 'bottom',
        textPosition: 'top-right',
      },
    ],
    text: '',
    buttons: {
      ':insight': 'Записать ответ',
    },
    img: birth_1,
    input_for: false,
    with_dice: false,
    statsDesc: 'Navigated to the page with the "Guide Gallery"',
    tag: 'Conductors',
  },
  {
    isUpdate: true,
    path: 'fields',
    textIsCenter: 'true',
    tooltip: [
      {
        text: ['Переходи к ежедневной практике написания ответа, Герой!'],
        position: 'top',
        textPosition: 'bottom-right',
      },
      {
        text: [
          `Материализуй свои мысли в слова для лучшего понимания себя и приближения к мечте. 
У тебя будет выбор, каким сделать ответ. Выбирай как чувствуешь: 
Публично - все будут знать, кто автор; 
Анонимно - кто автор будут знать только в чате Проводника; 
Пропустить - написать в следующий раз;`,
        ],
        position: 'bottom',
        textPosition: 'top-right',
      },
    ],
    text: '',
    buttons: {
      ':report': 'Записать ответ',
    },
    img: birth,
    input_for: false,
    with_dice: false,
    statsDesc: 'Navigated to the page with the "Guide Gallery"',
    tag: 'Conductors',
  },
  {
    isUpdate: true,
    path: 'arrow',
    textIsCenter: 'true',
    tooltip: [
      {
        text: ['Переходи к ежедневной практике написания ответа, Герой!'],
        position: 'top',
        textPosition: 'bottom-right',
      },
    ],
    text: '',
    buttons: {
      ':arrow': 'Дальше',
    },
    img: arrow,
    input_for: false,
    with_dice: false,
    statsDesc: 'Navigated to the page with the "Guide Gallery"',
    tag: 'Conductors',
  },
  // fields
];
