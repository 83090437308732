import React from 'react';

const LoadingSVG: React.FC = () => (
  <svg
  xmlns="http://www.w3.org/2000/svg" 
  viewBox="0 0 64 64"
>
  <defs>
    <linearGradient id="g" x1="-300%" x2="-200%" y1="0" y2="0">
      <stop offset="-10%" stop-color="rgba(0, 0, 0, 0.1)" />
      <stop offset="30%" stop-color="rgba(0, 0, 0, 0.07)" />
      <stop offset="70%" stop-color="rgba(0, 0, 0, 0.07)" />
      <stop offset="110%" stop-color="rgba(0, 0, 0, 0.1)" />
      <animate attributeName="x1" from="-300%" to="800%" dur="3s" repeatCount="indefinite"/>
      <animate attributeName="x2" from="-200%" to="900%" dur="3s" repeatCount="indefinite"/>
    </linearGradient>
  </defs>
  <rect 
    fill="#ffffff" 
    width="100%"
    height="100%"
  />
  <rect 
    fill="url(#g)" 
    width="100%"
    height="100%"
  />
  <script/>
</svg>
);

export default LoadingSVG;