import React, {useState} from 'react';
import './read-mode.css';
import {BackButton} from '@vkruglikov/react-telegram-web-app';
import HeaderMenu from '../header-menu/header-menu';

interface ReadModeProps {
  title: string;
  text: string;
  goBack: () => void;
  pauseAudio: () => void;
}

const ReadModeScreen: React.FC<ReadModeProps> = ({
  title,
  text,
  goBack,
  pauseAudio,
}) => {
  return (
    <div className='container no-restriction'>
      <BackButton onClick={goBack} />
      <HeaderMenu pauseAudio={pauseAudio} />
      <div className='title__container mt-top b-text'>
        <div className='title'>{title}</div>
      </div>
      <div className='insightInfo__container'>
        <div className='insightInfo wrap-lines'>{text}</div>
      </div>
    </div>
  );
};

export default ReadModeScreen;
