import React, {FC} from 'react';
import './tooltip.css';

export interface TooltipProps {
  text?: string[];
  textPosition?: string;
  position?: string;
  isNarrow?: string;
  textCenter?: boolean;
  isTransparent?: boolean;
}
interface TooltipComponentProps {
  tooltip: TooltipProps;
}

const Tooltip: FC<TooltipComponentProps> = ({tooltip}) => {
  return (
    <div
      className={`tooltip-container ${tooltip.isNarrow} ${tooltip.textCenter ? 'text-position-center' : ''}`}
    >
      <div
        className={`tooltip-text  ${tooltip.textPosition} ${tooltip.isTransparent ? 'transparent' : ''}`}
      >
        {tooltip.text?.map((item, i) => <p key={i}>{item}</p>)}
      </div>
    </div>
  );
};

export default Tooltip;
