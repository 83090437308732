import React, {useState, useEffect} from 'react';
import LoaderSVG from '../loader-svg/loader-svg';

interface ImageWithLoaderProps {
  src: string;
  alt: string;
  className: string;
}

const ImageWithLoader: React.FC<ImageWithLoaderProps> = ({
  src,
  alt,
  className,
}) => {
  const [loading, setLoading] = useState(true);

  // Обновление состояния при смене URL изображения
  useEffect(() => {
    // setLoading(true);
  }, [src]);

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <div className={className}>
      {loading && (
        <div
          style={{
            width: '100%',
            height: '100%',
            margin: '0',
          }}
        >
          <LoaderSVG />
        </div>
      )}
      <img
        src={src}
        alt={alt}
        onLoad={handleImageLoad}
        className={className}
        style={{display: loading ? 'none' : 'block'}}
      />
    </div>
  );
};

export default ImageWithLoader;
