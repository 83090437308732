import React, {useEffect, useState} from 'react';
import './make-move.css';
import img_field from '../../assets/images/field.jpg';
import Dice from '../dice/dice';
import {getRandomInt} from '../../shared/helpers/randomDice';
import {useSelector} from 'react-redux';
import {RootState} from '../../store/store';

interface MakeMoveProps {
  setShowAnimation: (state: boolean) => void;
  showAnimation: boolean;
  onAnimationEnd: (num: number) => void;
  totalLives: number;
}

const MakeMoveScreen: React.FC<MakeMoveProps> = ({
  setShowAnimation,
  showAnimation,
  onAnimationEnd,
  totalLives,
}) => {
  const {state} = useSelector((state: RootState) => state.dice);
  const [cell, setCell] = useState(0);
  const [numbers, setNumbers] = useState({start: 1, end: 7});
  console.log(state.live);
  useEffect(() => {
    if (state.live !== 0) {
      return;
    }
    if (cell === 62) {
      setNumbers((prev) => ({...prev, start: 6, end: 7}));
    }
    if (cell === 67) {
      setNumbers((prev) => ({...prev, start: 1, end: 2}));
    }
    if (cell === 66) {
      setNumbers((prev) => ({...prev, start: 2, end: 3}));
    }
    if (cell === 65) {
      setNumbers((prev) => ({...prev, start: 3, end: 4}));
    }
    if (cell === 64) {
      setNumbers((prev) => ({...prev, start: 4, end: 5}));
    }
  }, [state, cell]);
  useEffect(() => {
    setCell(state.sell);
  }, [state.sell]);
  // <div className={`${life !== 0 ? 'new-bg' : ''} cell_container `}>
  return (
    <div className={`cell_container ${state.live !== 0 ? 'new-bg' : ''} cube`}>
      <div className='move__dice__container'>
        <Dice
          num={getRandomInt(numbers.start, numbers.end)}
          setShowAnimation={setShowAnimation}
          showAnimation={showAnimation}
          onAnimationEnd={onAnimationEnd}
          showPoints={true}
        />
      </div>{' '}
      <div className='cube-text'>
        <p className='text-box'>{'Нажми чтобы бросить'}</p>
      </div>
    </div>
  );
};

export default MakeMoveScreen;
