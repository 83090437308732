import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useNavigate, useParams, useLocation} from 'react-router-dom';
import InsightScreen from '../components/insight/insight';
import {useHapticFeedback} from '@vkruglikov/react-telegram-web-app';
import {useInitData} from '@vkruglikov/react-telegram-web-app';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '../store/store';
import {getUserStats} from '../store/stats/actions';

const InsightScreenContainer: React.FC = () => {
  const location = useLocation();
  const {option} = location.state || {};

  console.log(option);

  const [impactOccurred, notificationOccurred, selectionChanged] =
    useHapticFeedback();
  const navigate = useNavigate();
  const [initDataUnsafe, initData] = useInitData() || '';
  const [inputValue, setInputValue] = useState<string>('');
  const [cellNumber, setCellNum] = useState(0);

  const [query, setQuery] = useState('');
  const dispatch: AppDispatch = useDispatch();

  const handleNext = async () => {
    impactOccurred('medium');
    if (await publish()) {
      setInputValue('');
      if (initData && initDataUnsafe?.user?.id) {
        dispatch(
          getUserStats(
            initData,
            initDataUnsafe?.user?.id,
            'Submitted the insight.',
            'Field',
          ),
        );
      }
    }
    cellNumber === 1
      ? navigate(`/welcome/insightDone`)
      : navigate(`/welcome/insightDoneAll`);
  };

  const [cellType, setCellType] = useState('');
  const fetchInitialData = async () => {
    try {
      const cellResponse = await axios.post(`/api/getUserCell`, {
        _auth: initData,
      });
      const {cell_type} = cellResponse.data.data;
      const {cell_num} = cellResponse.data.data;
      setCellNum(cell_num);
      setCellType(cell_type || '');
    } catch (error) {
      console.error('Ошибка при отправке данных', error);
    }
  };

  useEffect(() => {
    localStorage.setItem('user-url', '/insight');
  }, []);

  const fetchQuery = async () => {
    try {
      const cellResponse = await axios.post(`/api/getUserInfo`, {
        _auth: initData,
        data: ['query'],
      });
      const {query} = cellResponse.data.data;
      setQuery(query || '');
    } catch (error) {
      console.error('Ошибка при отправке данных', error);
    }
  };

  useEffect(() => {
    fetchQuery();
  }, []);

  const publish = async () => {
    console.log(inputValue);
    try {
      if (inputValue) {
        const response = await axios.post(`/api/publish`, {
          _auth: initData,
          insight: inputValue,
          option: option, //! проверить!
        });
        return true;
      }
    } catch (error) {
      console.error('Ошибка при отправке данных', error);
    }
    return false;
  };

  return (
    <InsightScreen
      handleClick={handleNext}
      query={query}
      setInputValue={setInputValue}
    />
  );
};

export default InsightScreenContainer;
