import React, {useEffect, useState} from 'react';
import './onboarding-welcome.css'; // Импортируем стили
import Dice from '../dice/dice';
import ImageWithLoader from '../image-with-loader/image-with-loader';
import {getRandomInt} from '../../shared/helpers/randomDice';
import path from 'path';
import Tooltip, {TooltipProps} from '../tooltip/tooltip';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '../../store/store';
import {useInitData} from '@vkruglikov/react-telegram-web-app';
import {TagProps, userStats} from '../../API/API';
import {getUserStats} from '../../store/stats/actions';
import {getDatabase, ref, update} from 'firebase/database';
import firebaseApp from '../../firebaseConfig';

import Error from '../Error/Error';
import axios from 'axios';
import {geStartApp} from '../../store/startapp/startappSlice';
import {links, randomLinks} from '../../constants/constants';

//images

import secondLiveRequest from '../../assets/images/secondLiveRequest.png';
import img2 from '../../assets/images/img-2.png';
import img3 from '../../assets/images/img-3.png';
import img4 from '../../assets/images/img-4.png';
import img5 from '../../assets/images/img-5.png';
import img6 from '../../assets/images/img-6.png';
import img7 from '../../assets/images/img-7.png';
import img8 from '../../assets/images/cube.png';
import img9 from '../../assets/images/img-9.png';
import img10 from '../../assets/images/img-10.png';

const getRandomLink = () => {
  const randomIndex = Math.floor(Math.random() * randomLinks.length);
  return randomLinks[randomIndex].url;
};

function findUrlById(value: number): string | null {
  const link = links.find((link) => link.id === value);
  return link ? link.url : null;
}
interface OnboardingWelcomeScreenProps {
  onNext: (selectedPath: string) => void;
  text: string;
  subtitle?: string;
  path: string;
  handleOptionClick: (e: string) => void;
  textIsCenter?: 'true' | 'none';
  tooltip?: TooltipProps[];
  handleNonUsual: () => void;
  isUpdate?: boolean;
  buttons: Record<string, string>;
  img: string;
  input_for: string | boolean;
  with_dice: boolean;
  statsDesc?: string;
  tag?: TagProps;
  maxDice: number;
  setShowAnimation: (state: boolean) => void;
  showDiceAnimation: boolean;
  diceFunc: () => void;
  setInputValue: (value: string) => void;
  onAnimationEnd: (num: number) => void;
  totalLives: any;
}

const OnboardingWelcomeScreen: React.FC<OnboardingWelcomeScreenProps> = ({
  onNext,
  subtitle,
  totalLives,
  text,
  isUpdate,
  tooltip,
  buttons,
  img,
  path,
  textIsCenter,
  handleNonUsual,
  input_for,
  with_dice,
  maxDice,
  setShowAnimation,
  handleOptionClick,
  showDiceAnimation,
  diceFunc,
  setInputValue,
  statsDesc,
  tag,
  onAnimationEnd,
}) => {
  const [backgroundImage, setBackgroundImage] = useState(img);
  useEffect(() => {
    if (totalLives !== 0) {
      // toCell
      if (path === 'insightDone' || path === 'insightDoneAll') {
        setBackgroundImage(img10);
        return;
      }

      if (path === 'toCell') {
        setBackgroundImage(img9);
        return;
      }
      if (path === 'Enter') {
        setBackgroundImage(secondLiveRequest);
        return;
      }
      if (path === 'ifCubesLess3-0') {
        setBackgroundImage(img6);
        return;
      }
      if (path === 'ifCubesLess3-1') {
        setBackgroundImage(img6);
        return;
      }
      if (path === 'ifCubesLess2') {
        setBackgroundImage(img5);
        return;
      }

      if (path === 'ifCubesLess1') {
        setBackgroundImage(img4);
        return;
      }
      if (path === 'Cube') {
        setBackgroundImage(img8);
        return;
      }
      if (path === 'ifCubesEnough') {
        setBackgroundImage(img7);
        return;
      }
      // ifCubesLess1
      if (path === 'ClarifyRequest') {
        setBackgroundImage(img2);
        return;
        // RigthRequest
      }
      if (path === 'RigthRequest') {
        setBackgroundImage(img3);
        // return;
        // RigthRequest
      }
    } else {
      setBackgroundImage(img);
    }
    console.log(totalLives, path);
  }, [totalLives, path, img]);

  const [inputValue, setInputValueState] = useState<string>('');
  const [link, setLink] = useState('');

  useEffect(() => {
    if (!id && link) localStorage.setItem('current-link', link);
  }, [link]);

  const {value: id} = useSelector((state: RootState) => state.start);

  useEffect(() => {
    const link = localStorage.getItem('current-link');

    const url = findUrlById(id);
    if (url) {
      setLink(url);
      return;
    }
    if (link) {
      setLink(link);

      return;
    }
    setLink(getRandomLink());
  }, [id]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValueState(value);
    setInputValue(value);
  };
  const [initDataUnsafe, initData] = useInitData() || '';

  useEffect(() => {
    // if (
    //   path == 'Cube' ||
    //   path == 'external' ||
    //   path == 'welcome/Cube' ||
    //   path == '/insight'
    // ) {
    //   return;
    // }
    localStorage.setItem('user-url', `welcome/${path}`);
  }, [path]);

  const dispatch: AppDispatch = useDispatch();
  const db = getDatabase(firebaseApp);

  const stats = async () => {
    if (initDataUnsafe?.user?.id)
      try {
        const reference = ref(db, 'users/' + initDataUnsafe?.user?.id);
        await update(reference, {conductor: link});
      } catch (error) {}
  };

  useEffect(() => {
    if (initData && initDataUnsafe?.user?.id && statsDesc && tag) {
      dispatch(
        getUserStats(initData, initDataUnsafe?.user?.id, statsDesc, tag),
      );
    }
  }, [dispatch, initData, initDataUnsafe?.user?.id, statsDesc, tag]);

  const fetchUserData = async () => {
    try {
      const response = await axios.post(`/api/getUserInfo`, {
        _auth: initData,
        data: ['chat_id'],
      });
      dispatch(geStartApp(response.data.data.chat_id));
    } catch (error) {
      console.error('Ошибка при отправке данных', error);
    }
  };
  useEffect(() => {
    fetchUserData();
  }, []);
  const setStats = () => {
    if (initData && initDataUnsafe?.user?.id) {
      dispatch(
        getUserStats(
          initData,
          initDataUnsafe?.user?.id,
          `The user clicked to select a conductor ${link}`,
          'Field',
        ),
      );
    }
    stats();
    onNext('external');
  };

  // const backgroundImage =
  //   totalLives !== 1 && path === 'Enter' ? secondLiveRequest : img;

  return (
    <div
      className='container '
      style={{
        background: `center/cover no-repeat url(${backgroundImage})`,
      }}
    >
      {path == 'Error' && <Error />}
      <div className='img__container'>
        {!isUpdate && (
          <ImageWithLoader src={img} alt={''} className={'progress__img'} />
        )}
      </div>{' '}
      {/* {path === 'ClarifyRequest' && (
        <div className='request-text'>
          <p className='text-box'>
            Твой запрос - <b>{inputValue}</b>
          </p>
        </div>
      )} */}
      {path !== 'Cube' && isUpdate && (text || subtitle || tooltip) && (
        <div
          className={`text__container text__container-transparent ${textIsCenter === 'true' ? 'flex-center' : ''}
          ${textIsCenter === 'none' ? 'flex-end' : ''}
          `}
        >
          {subtitle && (
            <div className='text-box'>
              <p className='subtitle'>{subtitle}</p>
            </div>
          )}
          {text && (
            <div className='text-box'>
              <div className='text wrap-lines'>
                {inputValue && 'Твой запрос: '}
                {inputValue ? (
                  <>
                    <br />
                    <span>{text}</span>
                  </>
                ) : (
                  text
                )}
              </div>
            </div>
          )}

          {tooltip?.map((item, i) => (
            <div
              key={i}
              className={`tooltip-position   ${textIsCenter == 'none' ? '' : 'text-center'} ${item?.position ? item?.position : ''} `}
            >
              <Tooltip tooltip={item} />
            </div>
          ))}
        </div>
      )}
      {!isUpdate && text !== '' && (
        <div className='text__container'>
          <div className='text wrap-lines'>{text}</div>
        </div>
      )}
      {input_for != false && (
        <div className='input__container'>
          <input
            type='text'
            className='input__input'
            placeholder='Твой запрос'
            value={inputValue}
            onChange={handleInputChange}
          />

          {inputValue &&
            Object.keys(buttons).length > 0 &&
            Object.entries(buttons).map(([buttonPath, buttonText]) => (
              <div className='input__button' onClick={() => onNext(buttonPath)}>
                <svg
                  width='32'
                  height='32'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 32 32'
                  style={{maxWidth: '65%'}}
                >
                  <path
                    d='m26.71 10.29-10-10a1 1 0 0 0-1.41 0l-10 10 1.41 1.41L15 3.41V32h2V3.41l8.29 8.29z'
                    fill='#ffffff'
                  />
                </svg>
              </div>
            ))}
        </div>
      )}
      {/* {(input_for === false || inputValue) && Object.keys(buttons).length > 0 && (

            <div className="buttons__container">
            {Object.entries(buttons).map(([buttonPath, buttonText]) => (
              <div className="button" onClick={() => onNext(buttonPath)}>
                {buttonText}
              </div>
            ))}
          </div>

        )} */}
      {input_for === false && Object.keys(buttons).length > 0 && (
        <div className='buttons__container'>
          {Object.entries(buttons).map(([buttonPath, buttonText]) => {
            if (buttonPath.startsWith(':insight')) {
              return (
                <button
                  // href={link}
                  // target='_blank'
                  // rel='noreferrer'
                  key={buttonPath}
                  className='button'
                  onClick={() => handleOptionClick('WriteReportAnon')}
                >
                  <div>{buttonText}</div>
                </button>
              );
            }
            if (buttonPath.startsWith(':arrow')) {
              return (
                <button
                  // href={link}
                  // target='_blank'
                  // rel='noreferrer'
                  key={buttonPath}
                  className='button'
                  onClick={() => handleOptionClick('WriteReportAnon')}
                >
                  <div>{buttonText}</div>
                </button>
              );
            }
            if (buttonPath.startsWith(':report')) {
              return (
                <div>
                  <div
                    className='cell__button'
                    onClick={() => handleNonUsual()}
                  >
                    {'Анонимно'}
                  </div>
                  {/* {!updateText && ( */}
                  <div
                    className='cell__button'
                    onClick={() => handleOptionClick('WriteReportUsual')}
                  >
                    Публично
                  </div>

                  {/* <div
                    className='cell__button'
                    onClick={() => handleOptionClick('skip')}
                  >
                    {'Пропустить'}
                  </div> */}
                </div>
              );
            }
            if (buttonPath.startsWith('external:')) {
              return (
                <a
                  href={link}
                  target='_blank'
                  rel='noreferrer'
                  key={buttonPath}
                  className='button'
                  onClick={setStats}
                >
                  <div>{buttonText}</div>
                </a>
              );
            }
            return (
              <div
                key={buttonPath}
                className='button'
                onClick={() => onNext(buttonPath)}
              >
                {buttonText}
              </div>
            );
          })}
        </div>
      )}
      {with_dice == true && (
        <div className='dice__container'>
          <Dice
            num={getRandomInt(totalLives === 0 ? 1 : 3, maxDice + 1)}
            setShowAnimation={setShowAnimation}
            showAnimation={showDiceAnimation}
            onAnimationEnd={onAnimationEnd}
            showPoints={false}
          />
        </div>
      )}
      {path === 'Cube' && (
        <div className='cube-text'>
          <p className='text-box'>{text}</p>
        </div>
      )}
    </div>
  );
};

export default OnboardingWelcomeScreen;
