// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app';
import {getAnalytics} from 'firebase/analytics';
import {getStorage} from 'firebase/storage';
import {getDatabase, ref, set} from 'firebase/database';
import {getFirestore, collection, getDocs} from 'firebase/firestore/lite';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBx0jj8sK4icxnVBJaDz7blOABy9k_BzTQ',

  authDomain: 'leelo-twa-statistics.firebaseapp.com',

  databaseURL:
    'https://leelo-twa-statistics-default-rtdb.europe-west1.firebasedatabase.app',

  projectId: 'leelo-twa-statistics',

  storageBucket: 'leelo-twa-statistics.appspot.com',

  messagingSenderId: '392743685736',

  appId: '1:392743685736:web:7e1d29efbe5ea5c2cdb5dd',

  measurementId: 'G-F8Q9KGGX58',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const firebaseApp = initializeApp(firebaseConfig);

export const db = getFirestore(firebaseApp);
const database = getDatabase(app);
// const analytics = getAnalytics(firebaseApp);
export default firebaseApp;
