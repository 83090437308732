export const links = [
  {url: 'https://t.me/+dgmucDScCZg3MTYy', id: -1002171118321},
  {url: 'https://t.me/+LcAMp6gNay5lYzdi', id: -1002228069357},
  {url: 'https://t.me/+NpJV-qUwLb4zYWIy', id: -1002039539721},
  {url: `https://t.me/+czDPId07be04ZDMy`, id: -1002187873337},
  {url: 'https://t.me/+pE53wCD7uvo0NmVi', id: -1002227694218},
  {url: 'https://t.me/+c4QhkR3JKaoyYzhi', id: -1002201242887},
  {url: 'https://t.me/+Y_8wjVS9_X5iM2Ey', id: -1002199356211},
  {url: 'https://t.me/+Rx6JwPwAhy03MGMy', id: -1002139081731},
  {url: 'https://t.me/+hRVjaIjEpZkyOTQ6', id: -1002228630971},
  {url: 'https://t.me/+lN6QaQFCE5ZhNTli', id: -1002249875323},
  {url: 'https://t.me/+A7nNHhPkuH9jOTUy', id: -1002230724269},
  {url: 'https://t.me/+73nAKyosGwFjMmIy', id: -1002180795109},
  {url: 'https://t.me/+jvhta__WG34xMTMy', id: -1002203917679},
  {url: 'https://t.me/+Dn9K8i-c2h05YzJi', id: -1002165449245},
  {url: 'https://t.me/+afqUhEK4brZhMjUy', id: -1002406811405},
  {url: 'https://t.me/+W5Ga-_cEsewyODQy', id: -1002351390562},
  {url: 'https://t.me/+XS7GjIM_9xsyNGZi', id: -1002208309594},
  {url: 'https://t.me/+3B4ft7MqW_8yNGNi', id: -1002224335321},
  {url: 'https://t.me/+MJsBPPK5uihmNjky', id: -1002136148823},
  {url: 'https://t.me/+wf_DxEwVpGoxMjUy', id: -1002227270786},
  {url: 'https://t.me/+E8GDmXdCues1YTYy', id: -1002165736114},
  {url: 'https://t.me/+L3P3f7kuRwRjODJi', id: -1002233306956},
  {url: 'https://t.me/+cSTWEgOsRyViZTIy', id: -1002192093130},
  {url: 'https://t.me/+uEmTXcX2qQY1M2Ji', id: -1002159773786},
  {url: 'https://t.me/+NwvAevaM-eAzZDNi', id: -1002138754074},
];
export const randomLinks = [
  {url: 'https://t.me/+dgmucDScCZg3MTYy', id: -1002171118321},
  {url: 'https://t.me/+LcAMp6gNay5lYzdi', id: -1002228069357},
  {url: 'https://t.me/+NpJV-qUwLb4zYWIy', id: -1002039539721},
  {url: `https://t.me/+czDPId07be04ZDMy`, id: -1002187873337},
  {url: 'https://t.me/+pE53wCD7uvo0NmVi', id: -1002227694218},
  {url: 'https://t.me/+c4QhkR3JKaoyYzhi', id: -1002201242887},
  {url: 'https://t.me/+Y_8wjVS9_X5iM2Ey', id: -1002199356211},
  {url: 'https://t.me/+Rx6JwPwAhy03MGMy', id: -1002139081731},
  {url: 'https://t.me/+hRVjaIjEpZkyOTQ6', id: -1002228630971},
  {url: 'https://t.me/+lN6QaQFCE5ZhNTli', id: -1002249875323},
  {url: 'https://t.me/+A7nNHhPkuH9jOTUy', id: -1002230724269},
  {url: 'https://t.me/+73nAKyosGwFjMmIy', id: -1002180795109},
  {url: 'https://t.me/+jvhta__WG34xMTMy', id: -1002203917679},
  {url: 'https://t.me/+Dn9K8i-c2h05YzJi', id: -1002165449245},
  {url: 'https://t.me/+afqUhEK4brZhMjUy', id: -1002406811405},
  {url: 'https://t.me/+W5Ga-_cEsewyODQy', id: -1002351390562},
  {url: 'https://t.me/+XS7GjIM_9xsyNGZi', id: -1002208309594},
  {url: 'https://t.me/+3B4ft7MqW_8yNGNi', id: -1002224335321},
  {url: 'https://t.me/+MJsBPPK5uihmNjky', id: -1002136148823},
  {url: 'https://t.me/+wf_DxEwVpGoxMjUy', id: -1002227270786},
  {url: 'https://t.me/+E8GDmXdCues1YTYy', id: -1002165736114},
  {url: 'https://t.me/+L3P3f7kuRwRjODJi', id: -1002233306956},
  {url: 'https://t.me/+cSTWEgOsRyViZTIy', id: -1002192093130},
  {url: 'https://t.me/+uEmTXcX2qQY1M2Ji', id: -1002159773786},
  {url: 'https://t.me/+NwvAevaM-eAzZDNi', id: -1002138754074},
];
