import React, {useEffect, useState} from 'react';
import './insight.css';
import img_request from '../../assets/images/ClarifyRequest.jpg';
import ImageWithLoader from '../image-with-loader/image-with-loader';
import {useInitData} from '@vkruglikov/react-telegram-web-app';
import {AppDispatch, RootState} from '../../store/store';
import {useDispatch, useSelector} from 'react-redux';
import {getUserStats} from '../../store/stats/actions';

interface InsightScreenProps {
  handleClick: () => void;
  query: string;
  setInputValue: (value: string) => void;
}

const InsightScreen: React.FC<InsightScreenProps> = ({
  handleClick,
  query,
  setInputValue,
}) => {
  const [inputValue, setInputValueState] = useState<string>('');
  const {fields} = useSelector((state: RootState) => state.field);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setInputValueState(value);
    setInputValue(value);
  };
  const [initDataUnsafe, initData] = useInitData() || '';
  const dispatch: AppDispatch = useDispatch();
  // console.Xlog(textIsCenter);
  useEffect(() => {
    if (initData && initDataUnsafe?.user?.id) {
      dispatch(
        getUserStats(
          initData,
          initDataUnsafe?.user?.id,
          'Navigated to the page for writing reports.',
          'Field',
        ),
      );
    }
  }, [dispatch, initData, initDataUnsafe?.user?.id]);
  return (
    <div className='cell_container ins'>
      <div className='insightInfo__box'>
        <div className='ins__row'>
          <div></div>
          {/* <div className='ins__info'>
            <div className='ins__text text-box'>
              <p className='ins__title'> {fields.title} 1. Рождение</p>
              <p className='ins__desc'>
                {' '}
                {fields.title} Игра начинается, когда удалось выбросить
                шестерку. Так пять элементов творения — эфир, воздух, огонь,
                вода и земля — соединяются с шестым: твоим сознанием. Подумай:
                Для чего ты рождён в этом мире? Какое у тебя предназначение?
              </p>
              {fields.text}
            </div>
          </div> */}
          <div>
            <div className='insightInfo__container'>
              <div className='ins__text text-box'>Tвой запрос -{query} </div>
              <br />
              <div className='ins__info'>
                <div className='ins__text text-box'>
                  <p className='ins__title'>
                    <b> {fields.title}</b>
                  </p>
                  <p className='ins__desc'> {fields.text}</p>
                </div>
                <br /> <br />
              </div>
              <div className='insightInfo text-white text-box'>
                Прямо сейчас отвлекись от рутинных дел, закрой глаза и
                представь, что ты достиг своей мечты. Мысленно перенесись в этот
                момент. И в поле ниже запиши ответы на вопросы: Что тебя
                окружает? Что ты чувствуешь? Как изменилась твоя жизнь? Ты?
              </div>
            </div>

            <div className='insight__container'>
              <textarea
                className='insight'
                value={inputValue}
                onChange={handleInputChange}
              />
              {inputValue && (
                <div className='ins-btn' onClick={handleClick}></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsightScreen;
