import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface iniitlaStateProps {
  value: number;
}
const initialState: iniitlaStateProps = {
  value: 0,
};

export const startappSlice = createSlice({
  name: 'startapp',
  initialState,
  reducers: {
    geStartApp: (state, action: PayloadAction<number>) => {
      state.value = action.payload;
    },
  },
});

export const {geStartApp} = startappSlice.actions;
export default startappSlice.reducer;
