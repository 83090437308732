import React, {useEffect, useState} from 'react';
import './cell.css'; // Импортируем стили
import Dice from '../dice/dice';
import ImageWithLoader from '../image-with-loader/image-with-loader';
import {getRandomInt} from '../../shared/helpers/randomDice';
import HeaderMenu from '../header-menu/header-menu';
import {AppDispatch} from '../../store/store';
import {useInitData} from '@vkruglikov/react-telegram-web-app';
import {useDispatch} from 'react-redux';
import {getUserStats} from '../../store/stats/actions';
import {getFields} from '../../store/cell/cellSlice';
import {useNavigate} from 'react-router-dom';
import Popup from '../Popup/Popup';

interface CellScreenProps {
  title: string;
  text: string;
  canMove: boolean;
  timeLeft: number;
  handleOptionClick: (option: string) => void;
  cellType: string;
  handleNonUsual: () => void;
  handleNextMove: () => void;
  handleFinal: () => void;
  handleGuide: () => void;
  img_field: any;
  isPlaying: boolean;
  togglePlay: () => void;
  pauseAudio: () => void;
  cellNum: number;
  handleRead: () => void;
  life: number;
  dice: number;
}

const CellScreen: React.FC<CellScreenProps> = ({
  title,
  text,
  cellNum,
  canMove,
  dice,
  timeLeft,
  handleOptionClick,
  cellType,
  handleNonUsual,
  handleNextMove,
  handleFinal,
  handleGuide,
  img_field,
  isPlaying,
  togglePlay,
  pauseAudio,
  handleRead,
  life,
}) => {
  const [showReportOptions, setShowReportOptions] = useState(false);

  const handleReportClick = () => {
    // navigate('/welcome/fields');
    setShowReportOptions(true);
  };

  const formatTime = (seconds: number): string => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const [initDataUnsafe, initData] = useInitData() || '';
  const dispatch: AppDispatch = useDispatch();
  useEffect(() => {
    dispatch(getFields({title: title, text: text, cellNum: cellNum}));
  }, [title, text, cellNum]);
  useEffect(() => {
    localStorage.setItem('user-url', 'cell');
  }, []);
  useEffect(() => {
    if (initData && initDataUnsafe?.user?.id && cellNum) {
      dispatch(
        getUserStats(
          initData,
          initDataUnsafe?.user?.id,
          `The user is on the field: ${cellNum}`,
          'Field',
        ),
      );
    }
  }, [dispatch, initData, initDataUnsafe?.user?.id, title]);

  const share = () => {
    if (initData && initDataUnsafe?.user?.id) {
      dispatch(
        getUserStats(
          initData,
          initDataUnsafe?.user?.id,
          'The user shared with friend',
          'Field',
        ),
      );
    }

    const encodedUrl = encodeURIComponent(
      `https://t.me/LeelaGameinBot/LeelaGamein`,
    );
    const encodedText = encodeURIComponent(
      ` 
🖖 Живи долго и процветай, Герой!

Я играю в самую древнюю и таинственную игру Лила.  
Сейчас я нахожусь на игровом поле на клетке ${title}.
Нахождение на этой клетке помогло мне под другим углом посмотреть на свою цель, открыть новые смыслы. Это стало для меня важным открытием! 

Я хочу поделиться с тобой тем, что, действительно, ценно. 
Хотя, есть один секрет - те, кто однажды начал играть в Лилу, могут никогда из нее не выйти. 
Поэтому хорошенько подумай прежде, чем начинать.
Ну, а я делюсь ссылкой`,
    );
    const telegramLink = `https://t.me/share/url?url=${encodedUrl}&text=${encodedText}`;
    window.open(telegramLink, '_blank', 'noopener,noreferrer');
    initData &&
      dispatch(
        getUserStats(
          initData,
          initDataUnsafe?.user?.id as number,
          `The user shared which cell they are on`,
          'Field',
        ),
      );
    // Открыть ссылку в новой вкладке
  };
  const navigate = useNavigate();
  const [updateText, setUpdateText] = useState(false);
  // useEffect(() => {
  //   life != 0 && cellNum == 1 && setUpdateText(true);
  // }, [life, cellNum]);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={`${life !== 0 ? 'new-bg' : ''} cell_container `}>
      <HeaderMenu pauseAudio={pauseAudio} share={share} />
      <div className='img__container'>
        <ImageWithLoader src={img_field} alt={''} className={'progress__img'} />
        {isOpen && (
          <Popup
            link={true}
            next={() => (life !== 0 ? navigate('welcome/endSecondLife') : {})}
            text='Ты еще не прожил это клетку, а значит нужный опыт все еще ждет тебя. Некоторым людям не хватает всей жизни, чтоб прожить всего одну клетку. Спешить некуда ведь на кону стоит твоя мечта. 

Переходи в Космос Лилы, возможно тот самый опыт тебя ждет там.'
            close={() => setIsOpen(!isOpen)}
          />
        )}
        {life != 0 ? (
          <div className='overlay' onClick={() => togglePlay()}>
            {isPlaying ? (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 64 64'
                width='64'
                height='64'
              >
                <circle cx='32' cy='32' r='32' fill='#000000' opacity='0.5' />
                <rect x='20' y='16' width='10' height='32' fill='#FFFFFF' />
                <rect x='34' y='16' width='10' height='32' fill='#FFFFFF' />
              </svg>
            ) : (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 64 64'
                width='64'
                height='64'
              >
                <circle cx='32' cy='32' r='32' fill='#000000' opacity='0.5' />
                <polygon points='24,16 48,32 24,48' fill='#FFFFFF' />
              </svg>
            )}
          </div>
        ) : null}
      </div>
      <div className={title ? 'box' : ''}>
        <div className='title__container'>
          <div className='title b-text'>{title}</div>
        </div>

        {life != 0 ? (
          <div
            className='insightInfo__container cursor_p'
            onClick={() => handleRead()}
          >
            <div className='insightInfo insightInfo-text'>{text} </div>
          </div>
        ) : (
          <div className='insightInfo__container'>
            <div className='insightInfo insightInfo-text'>{text}</div>
          </div>
        )}
      </div>
      {/* <div className='cell__button' onClick={() => handleFinal()}>
        {'Завершить'}
      </div> */}
      <div className='cell__buttons__container'>
        <div></div>

        {cellType === 'final' ? (
          <div>
            <div className='cell__button' onClick={() => handleFinal()}>
              {'Завершить'}
            </div>
          </div>
        ) : cellType === 'snake' ? (
          <>
            <div className='cell__button' onClick={() => handleNonUsual()}>
              {'Перейти по змее'}
            </div>
          </>
        ) : cellType === 'arrow' ? (
          <>
            <div className='cell__button' onClick={() => handleNonUsual()}>
              {'Перейти по стреле'}
            </div>{' '}
          </>
        ) : cellType === 'fast' ? (
          <>
            <div className='cell__button' onClick={() => handleNextMove()}>
              Сделать ход
            </div>
          </>
        ) : life === 0 ? (
          <>
            <div className='cell__button' onClick={() => handleNextMove()}>
              {'Сделать ход '}
            </div>
          </>
        ) : cellType === 'guide' ? (
          <div className='cell__button' onClick={() => handleGuide()}>
            {'Далее'}
          </div>
        ) : canMove ? (
          showReportOptions ? (
            <div>
              <div
                className='cell__button'
                onClick={() => handleOptionClick('WriteReportAnon')}
              >
                Анонимно
              </div>
              {/* {!updateText && ( */}
              <div
                className='cell__button'
                onClick={() => handleOptionClick('WriteReportUsual')}
              >
                Публично
              </div>

              {/* <div
                className='cell__button'
                onClick={() => handleOptionClick('skip')}
              >
                {'Пропустить'}
              </div> */}
            </div>
          ) : !updateText ? (
            <div className='cell__button' onClick={handleReportClick}>
              Записать ответ
            </div>
          ) : (
            // <div
            //   className='cell__button'
            //   onClick={() => navigate('/welcome/bithField')}
            // >
            //   Продолжить
            // </div>
            <div className='cell__button' onClick={handleReportClick}>
              Записать ответ
            </div>
          )
        ) : (
          <div
            className='cell__button disabled'
            onClick={(e) => setIsOpen(!isOpen)}
          >
            {`Осталось ${formatTime(timeLeft)}`}
          </div>
        )}
      </div>
    </div>
  );
};

export default CellScreen;
