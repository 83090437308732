import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface iniitlaStateProps {
  state: {sell: number; live: number};
}
const initialState: iniitlaStateProps = {
  state: {sell: 0, live: 0},
};

export const diceSlice = createSlice({
  name: 'dice',
  initialState,
  reducers: {
    getCellNumber: (
      state,
      action: PayloadAction<{sell: number; live: number}>,
    ) => {
      state.state = action.payload;
    },
  },
});

export const {getCellNumber} = diceSlice.actions;
export default diceSlice.reducer;
