'use client';

import {ReactNode, useEffect, useState} from 'react';
import {Provider, useDispatch} from 'react-redux';
import store, {AppDispatch} from '../store/store';
import updateUserData from '../hooks/Stats';
import {getDatabase, ref, set, update} from 'firebase/database';

import firebaseApp from '../firebaseConfig';
import {useInitData, useWebApp} from '@vkruglikov/react-telegram-web-app';
import {geStartApp} from '../store/startapp/startappSlice';
import axios from 'axios';
declare global {
  interface Window {
    Telegram: any; // Укажите точный тип, если он известен
  }
}

const ReduxProvider = ({children}: {children: ReactNode}) => {
  if (typeof window !== 'undefined') {
    document.addEventListener('visibilitychange', function () {
      if (document.hidden) {
        document.body.style.filter = 'blur(10px)'; // Пример: затенение содержимого
      } else {
        document.body.style.filter = 'none';
      }
    });
  }

  const [initDataUnsafe, initData] = useInitData() || '';
  const db = getDatabase(firebaseApp);
  const [enter, setEnter] = useState(1);

  useEffect(() => {
    // Получаем текущее значение из localStorage
    const enterL = localStorage.getItem('enter');
    // Если значение существует, устанавливаем его в состояние
    if (enterL) {
      setEnter(Number(enterL));
    } else {
      // Если значения нет, устанавливаем начальное значение 0
      setEnter(0);
    }
  }, []);

  useEffect(() => {
    // Увеличиваем значение на 1 и сохраняем в localStorage
    setEnter((prev) => {
      const newValue = prev + 1;
      localStorage.setItem('enter', `${newValue}`);
      return newValue;
    });
  }, []);

  useEffect(() => {
    const stats = async () => {
      if (initDataUnsafe?.user?.id)
        try {
          const reference = ref(db, 'users/' + initDataUnsafe?.user?.id);
          await update(reference, {numberOpenings: enter});
        } catch (error) {}
    };

    stats();
  }, [enter]);

  useEffect(() => {
    const stats = async () => {
      const date = new Date();

      const hours = date.getHours();
      const minutes = date.getMinutes();
      try {
        const reference = ref(db, 'users/' + initDataUnsafe?.user?.id);
        await update(reference, {
          isLogin: true,
          numberOpenings: enter,
          username: initDataUnsafe?.user?.username,
          time: `${hours}:${minutes}`,
        });

        console.log('Data successfully written');
      } catch (error) {
        console.error('Error writing data:', error);
      }
      console.log('Async function completed');
    };

    stats();
    console.log('Async function called');
  }, []);
  const fetchUserData = async () => {
    try {
      const response = await axios.post(`/api/getUserInfo`, {
        _auth: initData,
        data: ['chat_id'],
      });
      console.log(response.data.data.chat_id);
    } catch (error) {
      console.error('Ошибка при отправке данных', error);
    }
  };
  useEffect(() => {
    fetchUserData();
    window.Telegram.WebApp.onEvent('mainButtonClicked', () => {
      console.log('mainButtonClicked is click');
    });
  }, []);
  return <Provider store={store}>{children}</Provider>;
};

export default ReduxProvider;
