import {userStats} from '../../API/API';
import {ActionsType, AUTH_STATS, USER_STATS} from './actions';
interface initialStateProps {
  userStats: any;
  authStats: any;
}

const initialState: initialStateProps = {
  userStats: {},
  authStats: {},
};

export const StatsReducer = (state = initialState, action: ActionsType) => {
  switch (action.type) {
    case USER_STATS: {
      return {
        ...state,
        userStats: action.payload,
      };
    }
    case AUTH_STATS: {
      return {
        ...state,
        authStats: action.payload,
      };
    }
    default:
      return state;
  }
};
