import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useNavigate, useParams, useLocation} from 'react-router-dom';
import MakeMoveScreen from '../components/make-move/make-move';
import {useHapticFeedback} from '@vkruglikov/react-telegram-web-app';
import {useInitData} from '@vkruglikov/react-telegram-web-app';

const MakeMoveScreenContainer: React.FC = () => {
  const [impactOccurred, notificationOccurred, selectionChanged] =
    useHapticFeedback();
  const navigate = useNavigate();
  const [initDataUnsafe, initData] = useInitData() || '';
  const [totalLives, setTotalLives] = useState(0);
  const [showAnimation, setShowAnimation] = useState(false);
  const fetchInitialData = async () => {
    try {
      const response = await axios.post(`/api/getUserMoves`, {_auth: initData});
      const {total_lives, total_moves, total_energies, total_elements, moves} =
        response.data.data;
      setTotalLives(total_lives || 0);
    } catch (error) {
      console.error('Ошибка при отправке данных', error);
    }
  };
  const handleDiceEnd = async (num: number) => {
    try {
      const response = await axios.post(`/api/makeMove`, {
        _auth: initData,
        dice: num,
      });
    } catch (error) {
      console.error('Fetch user info error:', error);
    }
    navigate(`/cell`);
  };

  return (
    <MakeMoveScreen
      setShowAnimation={setShowAnimation}
      showAnimation={showAnimation}
      totalLives={totalLives}
      onAnimationEnd={handleDiceEnd}
    />
  );
};

export default MakeMoveScreenContainer;
