import axios from 'axios';

export type TagProps = 'Welcome' | 'Request' | 'Field' | 'Conductors';

export function userStats(
  initData: string,
  userId: number,
  event: string,
  tag: TagProps,
) {
  return axios.post(
    'https://statsreallife.feeltech.tech/stats/event',
    {
      user_id: userId,
      event: event,
      tag: tag,
      source: 'tma',
    },
    {
      headers: {
        auth: initData,
      },
    },
  );
}
export function authUserStats(
  initData: string,
  username: string,
  id: number,
  is_premium: boolean,
  lang: string,
) {
  return axios.post(
    'https://statsreallife.feeltech.tech/stats/user',
    {
      id: id,
      username: username,
      is_bot: false,
      is_premium: is_premium,
      language_code: lang,
    },
    {
      headers: {
        auth: initData,
      },
    },
  );
}
