import React, { useEffect, useState } from 'react';
import Lottie from 'lottie-react'
import pngex from '../../assets/images/cube.svg'
import cube1 from '../../assets/animations/cube_1.json'
import cube2 from '../../assets/animations/cube_2.json'
import cube3 from '../../assets/animations/cube_3.json'
import cube4 from '../../assets/animations/cube_4.json'
import cube5 from '../../assets/animations/cube_5.json'
import cube6 from '../../assets/animations/cube_6.json'

import './dice.css';
import { motion, AnimatePresence } from 'framer-motion';


interface DiceProps {
  num: number;
  setShowAnimation: (state: boolean) => void;
  showAnimation: boolean;
  onAnimationEnd: (num: number) => void;
  showPoints: boolean;
}

const Dice: React.FC<DiceProps> = ({ num, setShowAnimation, showAnimation, onAnimationEnd, showPoints }) => {
  const cubes = [cube1, cube2, cube3, cube4, cube5, cube6];
  const [points, setPoints] = useState<number | null>(null);
  const handleAnimationEnd = () => {
    if (showPoints) {
      setPoints(num);
      setTimeout(() => {
        setPoints(null);
        onAnimationEnd(num);
      }, 1000); // Убираем очки через 2 секунды
    } else {
      onAnimationEnd(num);
    }
  }

  return (
    <div onClick={() => setShowAnimation(true)} className="dice">
      {showAnimation ? (
        <div>
          <Lottie animationData={cubes[num - 1]} loop={0} onComplete={handleAnimationEnd}/>
          <AnimatePresence>
            {points !== null && (
              <motion.div 
                className="points"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: -100 }}
                exit={{ opacity: 0, y: -150 }}
                transition={{ duration: 1 }}
              >
                +{points}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ) : (
        <img src={pngex} alt="Бросить кубик" className="dice__preimg"/>
      )}
    </div>
  );
};

export default Dice;