import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import LoaderScreen from '../components/loader/loader';
import {welcomeScreenData} from '../data/onboarding-welcome';
import {useThemeParams} from '@vkruglikov/react-telegram-web-app';
import {useInitData} from '@vkruglikov/react-telegram-web-app';
import {useExpand} from '@vkruglikov/react-telegram-web-app';
import axios from 'axios';

const LoaderScreenContainer: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [colorScheme, themeParams] = useThemeParams();
  const [initDataUnsafe, initData] = useInitData() || '';
  const [isExpanded, expand] = useExpand();

  useEffect(() => {
    const url = localStorage.getItem('user-url');
    console.log(url);
    !isExpanded && expand();
    const getUserInfo = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post(`/api/getUserInfo`, {
          _auth: initData,
          data: ['registered', 'lives_lived'],
        });

        // if (
        //   // (url && url !== 'Cube') ||
        //   // (url && url !== 'cell') ||
        //   // (url && url !== 'external') ||
        //   // (url && url !== 'welcome/Cube') ||
        //   // (url && url !== '/insight')

        //   url == 'Cube' ||
        //   url == 'cell' ||
        //   url == 'external' ||
        //   url == 'welcome/Cube' ||
        //   url == '/insight'
        // ) {
        //   return;
        // }
        if (
          url == 'welcome/insightDone' ||
          url == 'welcome/ComeCloser' ||
          url == 'welcome/Hide' ||
          url == 'welcome/PresentBuddha' ||
          url == 'welcome/AnswerQuestion' ||
          url == 'welcome/BuddhaNice' ||
          url == 'welcome/AboutGuides2' ||
          url == '/cell' ||
          url == 'welcome/Request' ||
          url == 'welcome/BuddhaAnswerQuestionWhy' ||
          url == 'welcome/Request' ||
          url == 'welcome/DoNotEnter' ||
          url == 'welcome/ClarifyRequest' ||
          url == 'welcome/RigthRequest' ||
          url == 'welcome/ifCubesEnough' ||
          url == 'welcome/ifCubesLess1' ||
          url == 'welcome/ifCubesLess2' ||
          url == 'welcome/ifCubesLess3-0' ||
          url == 'welcome/ifCubesLess3-1' ||
          url == 'welcome/secondLive' ||
          url == 'welcome/toCell' ||
          url == 'welcome/insightDone' ||
          url == 'welcome/insightDoneAll' ||
          url == 'welcome/StartGuide' ||
          url == 'welcome/endSecondLife' ||
          url == 'welcome/ContactBuddha' ||
          url == 'welcome/Error' ||
          url == 'welcome/bithField' ||
          url == 'welcome/fields' ||
          url == 'welcome/arrow' ||
          url == 'welcome/Enter' ||
          url == 'welcome/ChooseGuide' ||
          url == 'insight' ||
          url == 'move' ||
          url == 'overview' ||
          url == 'welcome/ContactBuddha' ||
          url == 'welcome/AboutGuides'
        ) {
          navigate(`/${url}`);
          return;
        }
        if (response.data.data.registered === true) {
          navigate('/cell');
        } else {
          if (response.data.data.lives_lived === 0) {
            navigate(`/welcome/${welcomeScreenData[0].path}`);
          } else {
            navigate(`/welcome/secondLive`);
          }
        }

        // if (response.data.data.lives_lived === 0 && !url) {
        //   navigate(`/welcome/${welcomeScreenData[0].path}`);
        // } else if (response.data.data.registered === true && url == 'cell') {
        //   navigate('/cell');
        // } else if (response.data.data.lives_lived !== 0) {
        //   navigate(`/welcome/secondLive`);
        // } else {
        //   navigate(`/${url}`);
        // }

        // if (response.data.data.registered === true) {
        //   navigate('/cell');
        // } else {
        //   if (response.data.data.lives_lived === 0) {
        //     navigate(`/welcome/${welcomeScreenData[0].path}`);
        //   } else {
        //     navigate(`/welcome/secondLive`);
        //   }
        // }
      } catch (error) {
        console.error('Fetch user info error:', error);
      } finally {
        setIsLoading(false);
      }
    };

    const timer = setTimeout(getUserInfo, 1500);

    return () => clearTimeout(timer);
  }, [navigate]);

  if (isLoading) {
    return <LoaderScreen color={themeParams.button_color} />;
  }

  return <LoaderScreen color={themeParams.button_color} />;
};

export default LoaderScreenContainer;
