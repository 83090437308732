'use client';

import {useInitData} from '@vkruglikov/react-telegram-web-app';
import axios from 'axios';
import {FC, useEffect, useState} from 'react';

const token = '1621079504:AAEAEpGJOVK6BUn_duYlqjSyIUnRUc07yCs';

interface UserPhotoProps {
  setPhotoUrl: (e: string) => void;
}

const UserPhoto: FC<UserPhotoProps> = ({setPhotoUrl}) => {
  const [userId, setUserId] = useState<number | undefined>(0);
  const [initDataUnsafe, initData] = useInitData() || '';

  useEffect(() => {
    initData && setUserId(initDataUnsafe?.user?.id);
  }, [initData]);

  useEffect(() => {
    if (userId) {
      const getUserProfilePhotos = async () => {
        try {
          const response = await axios.get(
            `https://api.telegram.org/bot${token}/getUserProfilePhotos`,
            {
              params: {
                user_id: userId,
                limit: 1,
              },
            },
          );

          const photos = response.data.result.photos;
          if (photos.length > 0 && photos[0].length > 0) {
            const fileId = photos[0][0].file_id;
            const fileResponse = await axios.get(
              `https://api.telegram.org/bot${token}/getFile`,
              {
                params: {
                  file_id: fileId,
                },
              },
            );

            const filePath = fileResponse.data.result.file_path;
            const fileUrl = `https://api.telegram.org/file/bot${token}/${filePath}`;
            setPhotoUrl(fileUrl);
          }
        } catch (error) {
          console.error('Error fetching user profile photos:', error);
        }
      };

      getUserProfilePhotos();
    }
  }, [userId, token]);

  return null;
};

export default UserPhoto;
