import React, {useEffect, useState} from 'react';
import './overview.css';
import {BackButton, useInitData} from '@vkruglikov/react-telegram-web-app';
import HeaderMenu from '../header-menu/header-menu';
import {EnergyProps, TotalElementsProps} from '../../screens/overview';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '../../store/store';
import {getUserStats} from '../../store/stats/actions';

import Popup from '../Popup/Popup';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import html2canvas from 'html2canvas';

interface OverviewScreenProps {
  totalLives: number;
  totalMoves: number;
  totalEnergies: EnergyProps | null;
  totalElements: TotalElementsProps | null;
  moves: string[];
  goBack: () => void;
  pauseAudio: () => void;
  onNext: (selectedPath: string) => void;
  buttonPath: string;
}

const OverviewScreen: React.FC<OverviewScreenProps> = ({
  totalLives,
  totalMoves,
  totalEnergies,
  totalElements,
  moves,
  goBack,
  pauseAudio,
  onNext,
  buttonPath,
}) => {
  const [initDataUnsafe, initData] = useInitData() || '';
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    if (initData && initDataUnsafe?.user?.id) {
      dispatch(
        getUserStats(
          initData,
          initDataUnsafe?.user?.id,
          'The user visited the page with the memoirs',
          'Field',
        ),
      );
    }
  }, [dispatch, initData, initDataUnsafe?.user?.id]);

  const [currentLink, setCurrentLink] = useState('overview');
  const navigate = useNavigate();
  useEffect(() => {
    if (currentLink) {
      localStorage.setItem('user-url', currentLink);
      console.log(currentLink, 'currentLink');
    }
  }, [currentLink]);
  useEffect(() => {
    const url = localStorage.getItem('user-url');

    if (url) {
      navigate(`/${url}`);
    }
  }, []);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [text, setText] = useState('');
  const saveSpace = () => {
    setText(
      'Данная функция сейчас в разработке. Мы сообщим, когда она станет доступна. Твои "Итоги игры" уже выгружены в аккаунт. Храни их они тебе еще понадобятся',
    );
    setIsOpenPopup(!isOpenPopup);

    if (initData && initDataUnsafe?.user?.id) {
      dispatch(
        getUserStats(
          initData,
          initDataUnsafe?.user?.id,
          'clicked on save space lila',
          'Field',
        ),
      );
    }
  };
  // const publish = async () => {
  //   return false;
  // };
  const [image, setImage] = useState('');
  useEffect(() => {
    const publishImage = async () => {
      // console.log(image);
      if (image) {
        try {
          const response = await axios.post(`/api/sendImage`, {
            _auth: initData,
            image: image.replace(/^data:image\/png;base64,/, ''),
          });
          // console.log(response);
          return true;
        } catch (error) {
          console.error('Ошибка при отправке данных', error);
        }
      }
    };

    publishImage();
  }, [image]);

  const [query, setQuery] = useState('');
  const fetchUserData = async () => {
    try {
      const response = await axios.post(`/api/getUserInfo`, {
        _auth: initData,
        data: ['query'],
      });
      setQuery(response.data.data.query);
    } catch (error) {
      console.error('Ошибка при отправке данных', error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);
  useEffect(() => {
    const totalDice = moves.reduce(
      (accumulator, move: any) => accumulator + move.dice,
      0,
    );
    const totalDays = moves.reduce(
      (accumulator, move: any) => accumulator + move.days_ago,
      0,
    );
    setState((prev) => ({...prev, dice: totalDice, days: totalDays}));
  }, [moves]);
  // console.log(moves);
  const [state, setState] = useState({days: 0, dice: 0});
  const savePage = async () => {
    setIsOpenPopup(!isOpenPopup);
    if (initData && initDataUnsafe?.user?.id) {
      dispatch(
        getUserStats(
          initData,
          initDataUnsafe?.user?.id,
          'clicked on upload file',
          'Field',
        ),
      );
    }
    setText(
      'Твои "Итоги игры" уже выгружены в аккаунт. Храни их они тебе еще понадобятся',
    );
    const element = document.getElementById('history-section');
    if (element)
      html2canvas(element).then((canvas) => {
        const dataURL = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = dataURL;
        link.download = 'history_section.png';
        setImage(link.href);
      });
  };
  const handleSavePage = async () => {
    setIsOpenPopup(!isOpenPopup);
    if (initData && initDataUnsafe?.user?.id) {
      dispatch(
        getUserStats(
          initData,
          initDataUnsafe?.user?.id,
          'clicked on upload file',
          'Field',
        ),
      );
    }
    setText(
      'Данная функция сейчас в разработке. Мы сообщим, когда она станет доступна. Твои "Итоги игры" уже выгружены в аккаунт. Храни их они тебе еще понадобятся',
    );

    const element = document.getElementById('history-section');
    if (element)
      html2canvas(element).then((canvas) => {
        const dataURL = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = dataURL;
        link.download = 'history_section.png';
        setImage(link.href);
      });
  };

  // console.log(buttonPath);
  const handleClick = () => {
    localStorage.setItem('user-url', buttonPath);
    onNext(buttonPath);
  };
  return (
    <div className='container overview'>
      {isOpenPopup && (
        <Popup
          text={text}
          next={handleClick}
          close={() => setIsOpenPopup(!isOpenPopup)}
        />
      )}
      <BackButton onClick={goBack} />
      <HeaderMenu pauseAudio={pauseAudio} />
      <div id='history-section' className='overview'>
        <div className='title__container mt-top'>
          <p>
            <b> Твой запрос:</b> {query}
          </p>
          <br />
        </div>{' '}
        <div className='energy-items'>
          <div className='energy-items__row'>
            <div className={`energy-items_item energy-items__life`}>
              <span></span>
              <p>
                <span className='name-item'> Прожито жизней</span> {totalLives}{' '}
              </p>
            </div>
          </div>
        </div>
        <div className='energy-items'>
          <div className='energy-items__row'>
            <div className={`energy-items_item energy-items__dice`}>
              <span></span>
              <p>
                <span className='name-item'> Сделано ходов</span> {totalMoves}
              </p>
            </div>
          </div>
        </div>{' '}
        <div className='energy-items'>
          <div className='energy-items__row'>
            <div className={`energy-items_item energy-items__day`}>
              <span></span>{' '}
              <p>
                <span className='name-item'> Дней в игре</span> {state.days}
              </p>
            </div>
          </div>
        </div>
        <div className='energy-items'>
          <div className='energy-items__row'>
            <div className={`energy-items_item energy-items__tokens`}>
              <span></span>
              <p>
                <span className='name-item'>Всего получено токенов</span>{' '}
                {state.dice}
              </p>
            </div>
          </div>
        </div>
        <div className='energy-items'>
          <p className='energy-items__title'>Собрано элементов:</p>

          <div className='energy-items__row'>
            {/* <div className={`energy-items_item energy-items__fire`}>
              <span></span>
              <p className='energy-item__component'>
                <span className='item-title'>'Воздух'</span>
                <span className='number'>5</span>
              </p>
            </div> */}
            {totalElements !== null &&
              (
                Object.keys(totalElements) as Array<keyof TotalElementsProps>
              ).map((prop) => (
                <div
                  className={`energy-items_item energy-items__${prop}`}
                  key={prop}
                >
                  <span></span>

                  <p className='energy-item__component'>
                    <span className='item-title'>
                      {' '}
                      {prop === 'air'
                        ? 'Воздух'
                        : prop === 'water'
                          ? 'Вода'
                          : prop === 'fire'
                            ? 'Огонь'
                            : 'Земля'}{' '}
                    </span>
                    <span className='number'>{totalElements[prop]}</span>
                  </p>
                </div>
              ))}
          </div>
          <br />
        </div>
        <div className='energy-items'>
          <p className='energy-items__title'>Собрано энергий:</p>
          <div className='energy-items__row'>
            {/* <div className={`energy-items_item energy-items__fire`}>
              <span></span>
              <p>Женской 0</p>
            </div> */}
            {totalEnergies !== null &&
              (Object.keys(totalEnergies) as Array<keyof EnergyProps>).map(
                (prop) => (
                  <div
                    className={`energy-items_item energy-items__${prop}`}
                    key={prop}
                  >
                    <span></span>
                    <p>
                      {prop === 'female' ? 'Женской ' : 'Мужской '}
                      {totalEnergies[prop]}
                    </p>
                  </div>
                ),
              )}
          </div>
          <br />
        </div>
        <div className='title__container '>
          <div className='title b-text'>{'История ходов:'}</div>
        </div>
        <div className='profile-desc desc__container'>
          <div className='desc left'>
            {moves.length !== 0 && (
              <div className='energy-item__row'>
                <p className='energy-item__number'>Номер</p>
                <p className='energy-item__cell'>Клетка</p>
                <p>Кубик</p>
                <p className='energy-item__days'>Дней</p>
              </div>
            )}
            {/* <div className='energy-item__row'>
            <p className='energy-item__number'>Номер</p>
            <p className='energy-item__cell'>Клетка</p>
            <p>Кубик</p>
            <p className='energy-item__days'>Дней</p>
          </div>
          <div className={`energy-items_item energy-items__step`}>
            <span>{0}</span>{' '}
            <p>
              <span className='symbol'> </span>
              <span className='energy-items__title'> Заблуждение</span>
              <span className='energy-items_dice'> +{5}</span>
              <span className='days-ago'>
                {totalLives !== 0 && `-${0}`}
              </span>{' '}
            </p>
          </div>{' '}
          <div className={`energy-items_item energy-items__step`}>
            <span>{0}</span>{' '}
            <p>
              <span className='symbol'> </span>
              <span className='energy-items__title'>Гнев
              <span className='energy-items_dice'> +{5}</span>
              <span className='days-ago'>
                {totalLives !== 0 && `-${0}`}
              </span>{' '}
            </p> */}
            {/* </div> */}
            {(moves as any).map((move: any, index: any) => (
              <div className={`energy-items_item energy-items__step`}>
                <span>{move.field}</span>{' '}
                <p key={index}>
                  {/* <span className='symbol'> {move.symbol}</span> */}
                  <span className='energy-items__title'>{move.name}</span>
                  <span className='energy-items_dice'>
                    {move.dice === 666 && '👁'}
                    {move.dice === 0 && '🔒'}
                    {move.dice !== 666 && move.dice !== 0 && move.dice
                      ? `+${move.dice}`
                      : move.symbol}
                  </span>
                  <span className='days-ago'>
                    {totalLives !== 0 && `-${move.days_ago}`}
                  </span>{' '}
                </p>
              </div>
            ))}
          </div>
          {/* <div className='desc left'> */}
        </div>
      </div>

      <div className='buttons__container overview-row'>
        <div key={buttonPath} className='button' onClick={handleSavePage}>
          {'Сохранить в Космос Лилы (NFT)'}
        </div>
        <br />
        <div className='button' onClick={savePage}>
          {'Выгрузить файлом'}
        </div>
      </div>
    </div>
  );
};

export default OverviewScreen;
