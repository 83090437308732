import {useInitData} from '@vkruglikov/react-telegram-web-app';
import {FC, ReactNode, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '../../store/store';
import {AuthUserStats} from '../../store/stats/actions';

interface AuthUserProps {
  children: ReactNode;
}

const AuthUser: FC<AuthUserProps> = ({children}) => {
  const [initDataUnsafe, initData] = useInitData() || '';

  const dispatch: AppDispatch = useDispatch();
  useEffect(() => {
    if (initDataUnsafe && initData)
      dispatch(
        AuthUserStats(
          initData,
          initDataUnsafe?.user?.username as string,
          initDataUnsafe?.user?.id as number,
          false,
          initDataUnsafe?.user?.language_code as string,
        ),
      );
  }, [dispatch, initData, initDataUnsafe]);
  return <>{children}</>;
};

export default AuthUser;
