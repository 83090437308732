import React, {useState, useEffect} from 'react';
import './header-menu.css'; // Импортируем стили
import ImageWithLoader from '../image-with-loader/image-with-loader';
import profileImg from '../../assets/images/profile.png';
import {motion} from 'framer-motion';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import {useHapticFeedback} from '@vkruglikov/react-telegram-web-app';
import {useInitData} from '@vkruglikov/react-telegram-web-app';
import UserPhoto from '../UserPhoto/UserPhoto';
import {AppDispatch} from '../../store/store';
import {useDispatch} from 'react-redux';
import {getUserStats} from '../../store/stats/actions';

interface HeaderMenuProps {
  pauseAudio: () => void;
  share?: () => void;
}

const HeaderMenu: React.FC<HeaderMenuProps> = ({pauseAudio, share}) => {
  const [impactOccurred, notificationOccurred, selectionChanged] =
    useHapticFeedback();
  const [initDataUnsafe, initData] = useInitData() || '';
  const navigate = useNavigate();
  const [tokenValue, setTokenValue] = useState(0);
  const profileVoid = async () => {
    impactOccurred('light');
    pauseAudio();
    navigate(`/profile`);
  };

  // const [initDataUnsafe, initData] = useInitData() || '';
  const dispatch: AppDispatch = useDispatch();
  const [isPopup, setIsPopup] = useState(false);
  const openPopup = () => {
    if (initData && initDataUnsafe?.user?.id) {
      dispatch(
        getUserStats(
          initData,
          initDataUnsafe?.user?.id,
          'The user clicked button share',
          'Field',
        ),
      );
    }
    setIsPopup(!isPopup);
  };

  const fetchValue = async () => {
    try {
      const response = await axios.post(`/api/getWallet`, {
        _auth: initData,
        data: ['value'],
      });
      const {value} = response.data.data;
      setTokenValue(value);
    } catch (error) {
      console.error('Ошибка при получении данных', error);
    }
  };

  useEffect(() => {
    fetchValue();
  }, []);
  const [img, setImg] = useState('');
  return (
    <>
      {isPopup ? (
        <div className='popup'>
          <div className='popup__container'>
            <div className='popup__box'>
              <button
                className='close-btn'
                onClick={(e) => setIsPopup(!isPopup)}
              >
                <span></span>
              </button>
              <p>
                Лила - сама древняя и таинственная игра в мире. Возможно, это
                твой первый опыт, но далеко не последний. Ведь в Лилу легко
                войти, но выйти уже не получится. Ты можешь застрять на клетке
                на целую вечность. Энергия правильных людей поможет тебе в этом
                путешествии. Приглашай сюда только тех, в ком уверен
              </p>

              <button className='button cell__button' onClick={share}>
                Впустить
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className='header__container'>
          <UserPhoto setPhotoUrl={(e) => setImg(e)} />
          <div className='token__continer'>
            <div className='token__symbol'>
              {/* <svg
            version='1.1'
            id='Layer_1'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
            x='0px'
            y='0px'
            viewBox='0 0 56 56'
            xmlSpace='preserve'
          >
            <style type='text/css'>
              {`.st0{fill:#0088CC;}.st1{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}`}
            </style>
            <circle className='st0' cx='28' cy='28' r='28' />
            <path
              className='st1'
              d='M20.2,18.5h15.7c0.6,0,1.1,0.1,1.7,0.4c0.7,0.3,1.1,0.8,1.3,1.2c0,0,0,0.1,0.1,0.1c0.3,0.5,0.5,1.1,0.5,1.8
              c0,0.6-0.1,1.2-0.5,1.8c0,0,0,0,0,0l-9.9,17c-0.2,0.4-0.6,0.6-1.1,0.6c-0.4,0-0.8-0.2-1.1-0.6l-9.7-17c0,0,0,0,0,0
              c-0.2-0.4-0.6-0.9-0.6-1.7c-0.1-0.7,0.1-1.3,0.4-1.9c0.3-0.6,0.8-1.1,1.5-1.3C19.1,18.5,19.8,18.5,20.2,18.5z M26.8,20.9h-6.6
              c-0.4,0-0.6,0-0.7,0.1c-0.2,0.1-0.3,0.2-0.4,0.4C19,21.5,19,21.7,19,21.9c0,0.1,0.1,0.2,0.3,0.6c0,0,0,0,0,0l7.5,13V20.9z
              M29.2,20.9v14.7l7.6-13.1c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2,0-0.4-0.1-0.5c-0.1-0.1-0.1-0.2-0.2-0.2c0,0-0.1-0.1-0.1-0.1
              c-0.2-0.1-0.4-0.1-0.7-0.1H29.2z'
            />
          </svg> */}
            </div>
            <div className='token__numbers'>
              <div className='token__short'></div>
              <div className='token__value'>{tokenValue}</div>
            </div>
          </div>
          <div className='header-btn'>
            <motion.button
              className='button cell__button'
              onClick={openPopup}
              initial={{opacity: 1}}
              animate={{opacity: [0.5, 1, 0.5]}} // Пульсация яркости // Постоянная пульсация
              transition={{
                duration: 1, // Продолжительность одного цикла
                ease: 'easeInOut',
                repeat: Infinity, // Бесконечное повторение
              }}
            >
              {' '}
            </motion.button>
          </div>

          <div className='profile__pic' onClick={profileVoid}>
            <ImageWithLoader
              src={img ? img : profileImg}
              alt={''}
              className={'profile__img'}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default HeaderMenu;
