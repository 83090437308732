import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface iniitlaStateProps {
  fields: any;
}
const initialState: iniitlaStateProps = {
  fields: {},
};

export const cellSlice = createSlice({
  name: 'cell',
  initialState,
  reducers: {
    getFields: (state, action: PayloadAction<any>) => {
      state.fields = action.payload;
    },
  },
});

export const {getFields} = cellSlice.actions;
export default cellSlice.reducer;
