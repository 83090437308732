import {FC} from 'react';
import './Error.css';

const Error: FC = () => {
  return (
    <div className='error'>
      <div className='error__container'>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, ducimus
          nostrum possimus beatae dolorum iste fugit obcaecati saepe accusantium
          voluptates quaerat omnis eius, vero, voluptatibus est unde velit alias
          facilis ea officia recusandae officiis! Quo obcaecati repellendus
          unde! Sapiente beatae aut placeat debitis. Maiores accusamus soluta a.
          Nihil, repudiandae neque.
        </p>
        <button className='button'>Выйти</button>
      </div>
    </div>
  );
};

export default Error;
