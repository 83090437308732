import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import CellScreen from '../components/cell/cell';
import {useHapticFeedback} from '@vkruglikov/react-telegram-web-app';
import {useInitData} from '@vkruglikov/react-telegram-web-app';
import img_field from '../assets/images/field/field.jpg';
import {useAudio} from '../shared/AudioContext';
import {AppDispatch, RootState} from '../store/store';
import {useDispatch, useSelector} from 'react-redux';
import {getCellNumber} from '../store/dice/diceSlice';

const CellScreenContainer: React.FC = () => {
  const [impactOccurred] = useHapticFeedback();
  const navigate = useNavigate();
  const [initDataUnsafe, initData] = useInitData() || '';

  const [cellNum, setCellNum] = useState(0);
  const [field, setField] = useState('img_field');
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [canMove, setCanMove] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [cellType, setCellType] = useState('');
  const [dice, setDice] = useState(0);
  const [life, setLife] = useState(0);

  const [reloadKey, setReloadKey] = useState(0);

  const {isPlaying, toggleAudio, pauseAudio, setAudioSource} = useAudio();

  const handleNonUsual = async () => {
    impactOccurred('medium');
    pauseAudio();
    try {
      await axios.post(`/api/makeMove`, {_auth: initData, dice, no_move: true});
    } catch (error) {
      console.error('Fetch user info error:', error);
    }
    setReloadKey((prevKey) => prevKey + 1);
  };

  const handleRead = async () => {
    impactOccurred('light');
    pauseAudio();
    navigate(`/read`);
  };

  const handleGuide = () => {
    impactOccurred('medium');
    pauseAudio();
    navigate(`/welcome/ChooseGuide`);
  };

  const handleNextMove = () => {
    impactOccurred('medium');
    pauseAudio();
    navigate(`/move`);
  };

  const handleFinal = () => {
    impactOccurred('medium');
    pauseAudio();
    if (life !== 0) {
      navigate(`/welcome/ChooseGuide2`);
      return;
    }
    navigate(`/welcome/ChooseGuide`);
    // : navigate(`/welcome/endSecondLife`);
    // navigate(`/overview`);
  };

  const handleNext = (option: String) => {
    impactOccurred('medium');
    pauseAudio();
    if (option === 'skip') {
      navigate(`/welcome/insightDone`);
    } else {
      navigate(`/insight`, {state: {option}});
      console.log(option, 75);
    }
  };

  const fetchInitialData = async () => {
    try {
      const cellResponse = await axios.post(`/api/getUserCell`, {
        _auth: initData,
      });
      const {
        cell_num,
        title,
        short_text,
        full_text,
        can_move,
        remain,
        cell_type,
        dice,
        life,
      } = cellResponse.data.data;
      setTitle(title || '');
      setText(short_text || '');
      setCanMove(can_move || false);
      setTimeLeft(remain || 86400);
      setCellType(cell_type || '');
      setDice(dice || 0);
      setLife(life || 0);
      setCellNum(cell_num);
      try {
        const imageUrl = `https://storage.yandexcloud.net/reallife-twa/ru/assets/images/field${cell_num}.jpg`;
        setField(imageUrl);
        const audioSource = `https://storage.yandexcloud.net/reallife-twa/ru/assets/audio/${cell_num}.mp3`;
        setAudioSource(audioSource);
      } catch (err) {
        console.error('Error loading image or audio:', err);
      }
    } catch (error) {
      console.error('Ошибка при отправке данных', error);
    }
  };
  const dispatch: AppDispatch = useDispatch();
  // const state = useSelector((state: RootState) => state.dice);

  useEffect(() => {
    dispatch(getCellNumber({sell: cellNum, live: life}));
  }, [cellNum]);
  useEffect(() => {
    fetchInitialData();
  }, [reloadKey]);

  useEffect(() => {
    if (timeLeft <= 0) {
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        if (prevTimeLeft <= 1) {
          clearInterval(intervalId);
          setCanMove(true);
          return 0;
        }
        return prevTimeLeft - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  return (
    <CellScreen
      title={title}
      text={text}
      canMove={canMove}
      timeLeft={timeLeft}
      handleOptionClick={handleNext}
      cellType={cellType}
      handleNonUsual={handleNonUsual}
      handleNextMove={handleNextMove}
      handleFinal={handleFinal}
      handleGuide={handleGuide}
      img_field={field}
      cellNum={cellNum}
      dice={dice}
      isPlaying={isPlaying}
      togglePlay={toggleAudio}
      pauseAudio={pauseAudio}
      handleRead={handleRead}
      life={life}
    />
  );
};

export default CellScreenContainer;
