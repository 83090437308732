import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useNavigate, useParams} from 'react-router-dom';
import {welcomeScreenData} from '../data/onboarding-welcome';
import OnboardingWelcomeScreen from '../components/onboarding-welcome/onboarding-welcome';
import {useHapticFeedback} from '@vkruglikov/react-telegram-web-app';
import {useInitData} from '@vkruglikov/react-telegram-web-app';
import {EmitAndSemanticDiagnosticsBuilderProgram} from 'typescript';
import {useAudio} from '../shared/AudioContext';

const OnboardingWelcomeScreenContainer: React.FC = () => {
  const [impactOccurred, notificationOccurred, selectionChanged] =
    useHapticFeedback();
  const [initDataUnsafe, initData] = useInitData() || '';
  const [showAnimation, setShowAnimation] = useState(false);
  const navigate = useNavigate();
  const {screenId} = useParams<{screenId?: string}>();
  const currentIndex = welcomeScreenData.findIndex(
    (screen) => screen.path === screenId,
  );
  const [inputValue, setInputValue] = useState<string>('');
  const [livesLived, setLivesLived] = useState(0);
  const [query, setQuery] = useState('');

  const register = async () => {
    type Data = {
      registered: boolean;
      query?: string; // `query` станет опциональным полем
    };
    let _data: Data = {registered: true};

    try {
      if (screenId === 'DoNotEnter') {
        _data.query =
          'Хочу, чтобы Лила помогла мне понять, чего я хочу на самом деле';
      }
      const response = await axios.post(`/api/setUserInfo`, {
        _auth: initData,
        data: _data,
      });
      const responseMove = await axios.post(`/api/makeMove`, {
        _auth: initData,
        dice: 1,
        no_move: true,
      });
    } catch (error) {
      console.error('Fetch user info error:', error);
    }
  };

  const getLivesLived = async () => {
    try {
      const response = await axios.post(`/api/getUserInfo`, {
        _auth: initData,
        data: ['lives_lived'],
      });
      const {lives_lived} = response.data.data;
      setLivesLived(lives_lived || 0);
    } catch (error) {
      console.error('Fetch user info error:', error);
    }
  };

  const getQuery = async () => {
    try {
      const response = await axios.post(`/api/getUserInfo`, {
        _auth: initData,
        data: ['query'],
      });
      const {query} = response.data.data;
      setQuery(query || 0);
    } catch (error) {
      console.error('Fetch user info error:', error);
    }
  };

  const guide_chosen = async () => {
    try {
      const response = await axios.post(`/api/setUserInfo`, {
        _auth: initData,
        data: {guide_chosen: true},
      });
    } catch (error) {
      console.error('Fetch user info error:', error);
    }
  };
  const [currentLink, setCurrentLink] = useState('');

  useEffect(() => {
    // if (
    //   currentLink == 'Cube' ||
    //   // currentLink == 'cell' ||
    //   currentLink == 'external' ||
    //   currentLink == 'welcome/Cube' ||
    //   currentLink == '/insight'
    // ) {
    //   return;
    // }
    if (currentLink) {
      localStorage.setItem('user-url', currentLink);
    }
  }, [currentLink]);
  useEffect(() => {
    const url = localStorage.getItem('user-url');
    if (url && url !== 'external' && url !== 'cell') {
      navigate(`/${url}`);
    }
  }, []);

  const handleNext = async (selectedPath: string) => {
    selectedPath !== 'move' && setCurrentLink(selectedPath);
    console.log(selectedPath, 'selectedPath');
    impactOccurred('medium');
    setShowAnimation(false);
    if (await changeUserInfo()) {
      setInputValue(''); // Очищаем значение после успешной отправки данных
    }
    if (screenId === 'DoNotEnter' || screenId === 'ifCubesEnough') {
      await register();
    }
    if (selectedPath === 'external') {
      if (screenId === 'ContactBuddha') {
        await guide_chosen();
      }
    } else {
      navigate(`/${selectedPath}`);
    }
  };

  const toggleAnimation = () => {
    setShowAnimation(true);
  };
  const [totalLives, setTotalLives] = useState(0);

  const fetchInitialData = async () => {
    try {
      const response = await axios.post(`/api/getUserMoves`, {_auth: initData});
      const {total_lives} = response.data.data;
      setTotalLives(total_lives || 0);
    } catch (error) {
      console.error('Ошибка при отправке данных', error);
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  console.log(initData);
  const handleDiceEnd = async (num: number) => {
    try {
      const response = await axios.post(`/api/getUserInfo`, {
        _auth: initData,
        data: ['tried', 'lives_lived'],
      });

      let tried = response.data.data.tried + 1;
      const livesLived = response.data.data.lives_lived === 0 ? 0 : 1;
      const selectedPath =
        num === 6
          ? 'welcome/ifCubesEnough'
          : tried < 3
            ? `welcome/ifCubesLess${tried}`
            : `welcome/ifCubesLess${tried}-${livesLived}`; // указываем пути переключения

      if (tried >= 3 && livesLived === 1) {
        tried = 0;
      }
      try {
        const responseSet = await axios.post(`/api/setUserInfo`, {
          _auth: initData,
          data: {tried: tried},
        });
      } catch (error) {
        console.error('Fetch user info error:', error);
      }

      setShowAnimation(false);
      navigate(`/${selectedPath}`);
    } catch (error) {
      console.error('Fetch user info error:', error);
    }
  };
  const [reloadKey, setReloadKey] = useState(0);
  const [dice, setDice] = useState(0);
  const {isPlaying, toggleAudio, pauseAudio, setAudioSource} = useAudio();
  const handleNonUsual = async () => {
    impactOccurred('medium');
    pauseAudio();
    try {
      await axios.post(`/api/makeMove`, {_auth: initData, dice, no_move: true});
    } catch (error) {
      console.error('Fetch user info error:', error);
    }
    setReloadKey((prevKey) => prevKey + 1);
  };
  const fetchInitialDatas = async () => {
    try {
      const cellResponse = await axios.post(`/api/getUserCell`, {
        _auth: initData,
      });
      const {dice} = cellResponse.data.data;

      setDice(dice || 0);
    } catch (error) {
      console.error('Ошибка при отправке данных', error);
    }
  };

  // const state = useSelector((state: RootState) => state.dice);

  useEffect(() => {
    fetchInitialDatas();
  }, [reloadKey]);

  const changeUserInfo = async () => {
    try {
      if (inputValue) {
        const response = await axios.post(`/api/setUserInfo`, {
          _auth: initData,
          data: {query: inputValue},
        });
        return true; // Успешная отправка данных
      }
    } catch (error) {
      console.error('Ошибка при отправке данных', error);
    }
    return false; // Ошибка при отправке данных
  };

  const currentScreen =
    currentIndex >= 0 ? welcomeScreenData[currentIndex] : welcomeScreenData[0];

  if (currentScreen.with_dice) {
    getLivesLived();
  }
  if (screenId === 'ClarifyRequest') {
    getQuery();
  }
  // useEffect(() => {
  //   // setCurrentLink(`/welcome/${currentScreen.path}`);
  // }, [currentScreen.path]);
  const handleOptionClick = (option: String) => {
    impactOccurred('medium');

    if (option === 'skip') {
      navigate(`/welcome/insightDone`);
    } else {
      navigate(`/insight`, {state: {option}});
    }
  };
  return (
    <OnboardingWelcomeScreen
      onNext={handleNext}
      text={
        screenId === 'ClarifyRequest'
          ? query + currentScreen.text
          : currentScreen.text
      }
      path={currentScreen.path}
      statsDesc={currentScreen.statsDesc}
      img={currentScreen.img}
      tooltip={currentScreen.tooltip}
      isUpdate={currentScreen.isUpdate}
      textIsCenter={currentScreen.textIsCenter}
      handleNonUsual={handleNonUsual}
      subtitle={currentScreen?.subtitle}
      buttons={currentScreen.buttons}
      handleOptionClick={handleOptionClick}
      input_for={currentScreen.input_for}
      tag={currentScreen.tag}
      with_dice={currentScreen.with_dice}
      totalLives={totalLives}
      maxDice={livesLived === 0 ? 5 : 6}
      setShowAnimation={setShowAnimation}
      showDiceAnimation={showAnimation}
      diceFunc={toggleAnimation}
      setInputValue={setInputValue}
      onAnimationEnd={handleDiceEnd}
    />
  );
};

export default OnboardingWelcomeScreenContainer;
