import React, { createContext, useContext, useState, ReactNode } from 'react';

interface AudioContextProps {
  isPlaying: boolean;
  toggleAudio: () => void;
  pauseAudio: () => void;
  setAudioSource: (source: string) => void;
}

interface AudioProviderProps {
  children: ReactNode;
}

const AudioContext = createContext<AudioContextProps | undefined>(undefined);

export const AudioProvider: React.FC<AudioProviderProps> = ({ children }) => {
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const toggleAudio = () => {
    if (audio) {
      if (!audio.paused){
        audio.pause();
        setIsPlaying(false);
      }else{
        audio.play();
        setIsPlaying(true);
      }
    }
  };

  const pauseAudio = () => {
    if (audio && !audio.paused) {
      audio.pause();
      setIsPlaying(false);
    }
  };

  const setAudioSource = (source: string) => {
    const newAudio = new Audio(source);
    setAudio(newAudio);
  };

  return (
    <AudioContext.Provider value={{ isPlaying, toggleAudio, pauseAudio, setAudioSource }}>
      {children}
    </AudioContext.Provider>
  );
};

export const useAudio = () => {
  const context = useContext(AudioContext);
  if (!context) {
    throw new Error('useAudio must be used within an AudioProvider');
  }
  return context;
};
