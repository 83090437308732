import {combineReducers} from 'redux';
import {StatsReducer} from './stats/reducer';
import diceSlice from './dice/diceSlice';
import cellSlice from './cell/cellSlice';
import startappSlice from './startapp/startappSlice';

export default combineReducers({
  stats: StatsReducer,
  dice: diceSlice,
  field: cellSlice,
  start: startappSlice,
});
