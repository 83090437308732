import {FC} from 'react';
import './Popup.css';

interface PopupProps {
  text: string;
  close: () => void;
  next?: () => void;
  link?: boolean;
}

const Popup: FC<PopupProps> = ({text, close, next, link}) => {
  return (
    <div className='popup-container'>
      <div className='popup-container__container'>
        <div className='popup-container__box'>
          <button className='close-btn' onClick={close}>
            <span></span>
          </button>
          <p>{text}</p>
          {!link ? (
            <button className='button popup-container__button' onClick={next}>
              Продолжить
            </button>
          ) : (
            <a
              href='https://t.me/addlist/VK8cBH4c6o8wMTNi'
              target='_blank'
              className='button popup-container__button'
            >
              Перейти в Космос Лилы
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default Popup;
