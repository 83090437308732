import React, {useEffect} from 'react';
import './App.css';
import OnboardingWelcomeScreenContainer from './screens/onboarding-welcome';
import LoaderScreenContainer from './screens/loader';
import CellScreenContainer from './screens/cell';
import InsightScreenContainer from './screens/insight';
import MakeMoveScreenContainer from './screens/make-move';
import ProfileScreenContainer from './screens/profile';
import ReadModeScreenContainer from './screens/read-mode';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import OverviewScreenContainer from './screens/overview';
import updateUserData from './hooks/Stats';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/beta072' element={<LoaderScreenContainer />} />
        <Route path='/profile' element={<ProfileScreenContainer />} />
        <Route path='/overview' element={<OverviewScreenContainer />} />
        <Route path='/cell' element={<CellScreenContainer />} />
        <Route path='/read' element={<ReadModeScreenContainer />} />
        <Route path='/insight' element={<InsightScreenContainer />} />
        <Route path='/move' element={<MakeMoveScreenContainer />} />
        <Route
          path='/welcome/:screenId'
          element={<OnboardingWelcomeScreenContainer />}
        />
      </Routes>
    </Router>
  );
}

export default App;
