import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import ProfileScreen from '../components/profile/profile';
import {useHapticFeedback} from '@vkruglikov/react-telegram-web-app';
import {useInitData} from '@vkruglikov/react-telegram-web-app';
import {useAudio} from '../shared/AudioContext';
import {EnergyProps, TotalElementsProps} from './overview';

const ProfileScreenContainer: React.FC = () => {
  const [impactOccurred] = useHapticFeedback();
  const navigate = useNavigate();
  const [initDataUnsafe, initData] = useInitData() || '';

  const [totalLives, setTotalLives] = useState(0);
  const [totalMoves, setTotalMoves] = useState(0);
  const [totalElements, setTotalElements] = useState<TotalElementsProps | null>(
    null,
  );
  const [totalEnergies, setTotalEnergies] = useState<EnergyProps | null>(null);
  const [moves, setMoves] = useState([]);

  const {pauseAudio} = useAudio(); // используем контекст

  const handleBack = () => {
    navigate('/cell');
  };

  const fetchInitialData = async () => {
    try {
      const response = await axios.post(`/api/getUserMoves`, {_auth: initData});
      const {total_lives, total_moves, total_energies, total_elements, moves} =
        response.data.data;
      setTotalMoves(total_moves || 0);
      setTotalLives(total_lives || 0);
      setTotalEnergies(total_energies || '');
      setTotalElements(total_elements || {});
      setMoves(moves || []);
    } catch (error) {
      console.error('Ошибка при отправке данных', error);
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  return (
    <ProfileScreen
      totalLives={totalLives}
      totalMoves={totalMoves}
      totalElements={totalElements}
      totalEnergies={totalEnergies}
      moves={moves}
      goBack={handleBack}
      pauseAudio={pauseAudio} // передаем функцию pauseAudio
    />
  );
};

export default ProfileScreenContainer;
