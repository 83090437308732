import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ReadModeScreen from '../components/read-mode/read-mode';
import { useHapticFeedback } from '@vkruglikov/react-telegram-web-app';
import { useInitData } from '@vkruglikov/react-telegram-web-app';
import { useAudio } from '../shared/AudioContext';

const ReadModeScreenContainer: React.FC = () => {
  const [impactOccurred] = useHapticFeedback();
  const navigate = useNavigate();
  const [initDataUnsafe, initData] = useInitData() || '';

  const [title, setTitle] = useState('');
  const [text, setText] = useState('');

  const { pauseAudio } = useAudio(); // используем контекст

  const handleBack = () => {
    navigate('/cell');
  };

  const fetchInitialData = async () => {
    try {
      const cellResponse = await axios.post(`/api/getUserCell`, { _auth: initData });
      const { title, full_text } = cellResponse.data.data;
      setTitle(title || '');
      setText(full_text || '');
    } catch (error) {
      console.error('Ошибка при получении данных', error);
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  return (
    <ReadModeScreen
      title={title}
      text={text}
      goBack={handleBack}
      pauseAudio={pauseAudio}
    />
  );
};

export default ReadModeScreenContainer;
