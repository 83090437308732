import {authUserStats, TagProps, userStats} from '../../API/API';
import {AppDispatch} from '../store';

export const USER_STATS = 'stats/USER_STATS';
export const AUTH_STATS = 'stats/AUTH_STATS';

export type ActionsType = UserStatsProps | AuthUserStatsProps;

type UserStatsProps = {
  type: typeof USER_STATS;
  payload: any;
};
export const getUserStats = (
  initData: string,
  id: number,
  sentData: string,
  tag: TagProps,
) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await userStats(initData, id, sentData, tag);
      const data = response.data;
      dispatch({type: USER_STATS, data: data.data});
    } catch (e: unknown) {
      console.error('Error:', e);
    }
  };
};
type AuthUserStatsProps = {
  type: typeof AUTH_STATS;
  payload: any;
};

export const AuthUserStats = (
  initData: string,
  username: string,
  id: number,
  is_premium: boolean,
  lang: string,
) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await authUserStats(
        initData,
        username,
        id,
        is_premium,
        lang,
      );
      const data = response.data;
      dispatch({type: AUTH_STATS, data: data.data});
    } catch (e: unknown) {
      console.error('Error:', e);
    }
  };
};
